import {selectAudio, selectImage, selectText, selectVideo} from "./template";
import SpeechText from "../../spech/SpeechText";
import React from "react";

export default function ActivityPartPreview (props){
    const {selectedActivityPart} = props;



    return (
        <div className="col-12 col-xl-4">
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="card shadow border-0 text-center p-0">

                        <div className="card-body pb-5">
                            <div
                                className={`form-check ${selectedActivityPart.isMain ? "alert alert-light border " : "mb-3 "}  w-100`}>

                                {
                                    selectedActivityPart.type === "TEXT" ? selectText(selectedActivityPart) :
                                        selectedActivityPart.type === "AUDIO" ? selectAudio(selectedActivityPart) :
                                            selectedActivityPart.type === "VIDEO" ? selectVideo(selectedActivityPart) :
                                                selectedActivityPart.type === "IMAGE" ? selectImage(selectedActivityPart) :
                                                    null
                                }
                                {
                                    selectedActivityPart.isMain ? <>
                                            <SpeechText
                                                getSpeechText={() => {
                                                }}
                                                questionNumber={1}
                                            />

                                            <div><b>{selectedActivityPart.answer}</b></div>

                                        </> :
                                        null
                                }
                                {
                                    selectedActivityPart.gains && Array.isArray(selectedActivityPart.gains) && selectedActivityPart.gains.length > 0 ?
                                        <>
                                            <hr/>
                                            {
                                                selectedActivityPart.gains.map((gain, key) =>
                                                    <div key={key} className="mt-2">
                                                        <div
                                                            className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                                            <fieldset className="w-100">
                                                                <div> {gain.gain}</div>

                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                        : null
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}