import Exam from "../app/pages/exam/Exam";

import {
  IconUsers
} from "../app/pages/components/Icons";
import ActivityEdit from "../app/pages/exam/ActivityEdit";
import ActivityPartEdit from "../app/pages/exam/ActivityPartEdit";
import DoExam from "../app/pages/exam/DoExam";
import ExamEdit from "../app/pages/exam/ExamEdit";


const dashboardRoutes = [

  {
    path: "/",
    name: "Exam List",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 0,
    sidePanelVisible: true,
    fullScreen: false
  },

  {
    path: "/applications",
    name: "Applications",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/report",
    name: "Report",
    icon: IconUsers,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },





  {
    path: "/exam/:examId/:activityId",
    name: "ActivityEdit",
    icon: IconUsers,
    component: ActivityEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/:examId/:activityId/:activityPartId",
    name: "ActivityPartEdit",
    icon: IconUsers,
    component: ActivityPartEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/:id",
    name: "Report",
    icon: IconUsers,
    component: DoExam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/create/:examId",
    name: "ExamEdit",
    icon: IconUsers,
    component: ExamEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  }

];

export default dashboardRoutes;
