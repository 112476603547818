import React from 'react'
import { Link } from 'react-router-dom'

export default function Lock() {
  return (
    <main>
    <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
      <div className="container">
        <Link
          to="/"
          className="d-flex align-items-center justify-content-center mb-4"
          ><svg
            className="icon icon-xs me-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          Back to homepage</Link>
        <div
          className="row justify-content-center form-bg-image"
          data-background-lg="../../assets/img/illustrations/signin.svg"
        >
          <div
            className="col-12 d-flex align-items-center justify-content-center"
          >
            <div
              className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500"
            >
              <div className="text-center text-md-center mb-4 mt-md-0">
                <div className="avatar avatar-lg mx-auto mb-3">
                  <img
                    className="rounded-circle"
                    alt="profile"
                    src="../../assets/img/team/profile-picture-3.jpg"
                  />
                </div>
                <h1 className="h3">Bonnie Green</h1>
                <p className="text-gray">Better to be safe than sorry.</p>
              </div>
              <form className="mt-5">
                <div className="form-group mb-4">
                  <label htmlFor="password">Your Password</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon2"
                      ><svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg> </span
                    ><input
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      id="password"
                      required
                    />
                  </div>
                </div>
                <div className="d-grid mt-3">
                  <button type="submit" className="btn btn-gray-800">
                    Unlock
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  )
}
