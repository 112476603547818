import { useState } from "react";
import routes from "../../configs/route";

export const useRole = () => {
  const [result, setResult] = useState([]);

  const checkRole = (path, role) => {
    const find = routes.find(
      (route) => route.path === path && route.auth.find((a) => a === role)
    );
    if (find) setResult(true);
    else setResult(false);
  };
  const checkMenuVisibility = (path) => {
    const falseList = routes.filter((route) => route.fullScreen === true);
    if (falseList.length > 0) {
      const equelPaths = falseList.filter((route) => route.path === path);
      if (equelPaths.length > 0) {
        setResult(false);
      } else {
        setResult(true);
      }
    } else {
      setResult(true);
    }
  };

  const handleChange = (type, path, role) => {
    if (type === "role") {
      checkRole(path, role);
    } else if (type === "menu") {
      checkMenuVisibility(path);
    }
  };
  return [result, handleChange];
};
