import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NoMatch from "./app/pages/single/NoMatch";
import AppContextProvider from "./configs/AppContextProvider";
import routes from "./configs/route";
import "./configs/i18n";
import Footer from "./app/pages/components/Footer";
import ShortMenu from "./app/pages/components/ShortMenu";
import MainMenu from "./app/pages/components/MainMenu";
import Header from "./app/pages/components/Header";
import "./assets/site.css";
import "./assets/MarkerCluster.Default.css";
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import 'react-big-calendar/lib/sass/styles';
//import 'react-big-calendar/lib/addons/dragAndDrop/styles';
import 'react-big-calendar/lib/css/react-big-calendar.css'


const root = ReactDOM.createRoot(document.getElementById("root"));

const compare = (a, b) => {
    if (a.sort < b.sort) {
        return -1;
    }
    if (a.sort > b.sort) {
        return 1;
    }
    return 0;
};

let role = "ADMIN"

if (localStorage.getItem("user_data"))
    role = JSON.parse(localStorage.getItem("user_data")).role


//ali@ali.com
root.render(
    <AppContextProvider>
        <BrowserRouter>
            <ShortMenu/>
            <MainMenu/>
            <main className="content">
                <Header/>
                {
                //  <ButtonMenu />
            }

                <Routes>
                    {
                        routes.sort(compare).map((route, key) => (
                            route.auth.includes(role) ?
                                <Route key={key} path={route.path} element={<route.component {...route} />}/> : null
                        ))
                    }
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
                <Footer/>
            </main>
        </BrowserRouter>
    </AppContextProvider>
);
