import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
import {PiExam} from "react-icons/pi";
import routes from "../../../configs/route";


export default function Header() {
    let location = useLocation();
    const [visibility, setVisibility] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const route = routes.find(route => route.path === location.pathname)
        if (route) {
            setVisibility(route.sidePanelVisible)
        }
    }, [location.pathname]);

    useEffect(() => {
        var user_data = localStorage.getItem("user_data")
        if (!user_data) {
            navigate("/sing-in");
        }
        setUser(JSON.parse(user_data))
    }, [location.pathname]);

    const sing_out = () => {
        localStorage.removeItem("user")
        localStorage.removeItem("user_data")
        navigate("/sing-in");
    }

    return (<React.Fragment>
            {visibility === true ?
                <nav
                    className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-2 bg-white my-2">
                    <div className="container-fluid px-0">
                        <div
                            className="d-flex justify-content-between w-100"
                            id="navbarSupportedContent"
                        >
                            <div className="d-flex align-items-center text-action">
                                <h3><PiExam className={"mx-3"}/> Speaking Management Application</h3>
                            </div>
                            <ul className="navbar-nav align-items-center">
                                <span className='m-2 btn btn-secondary'>{user?.name} {user?.lastname}</span>
                                <button className='m-2 btn btn-danger' onClick={() => sing_out()}>Logout</button>
                            </ul>
                        </div>
                    </div>
                    <hr/>
                </nav>
                : null}
            <NotificationContainer/>
        </React.Fragment>
    );
}
