import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import routes from "../../../configs/route";

export default function Footer() {
  let location = useLocation();
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {

    const route = routes.find(route => route.path === location.pathname)
    if(route){
      setVisibility(route.sidePanelVisible)
    }
  });
  
  return (
    visibility === true ?
    <footer className="bg-white rounded shadow p-5 mb-4 mt-4">
      <div className="row">
        <div className="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
          <p className="mb-0 text-center text-lg-start">
            © 2024-<span className="current-year"></span>
            <Link
              className="text-primary fw-normal"
              to="#"
              target="_blank"
            >
              Elta
            </Link>
          </p>
        </div>
        <div className="col-12 col-md-8 col-xl-6 text-center text-lg-start">
          <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
            <li className="list-inline-item px-0 px-sm-2">
              <Link href="#">Elta</Link>
            </li>
            <li className="list-inline-item px-0 px-sm-2">
              <Link to="#">Education</Link>
            </li>
            <li className="list-inline-item px-0 px-sm-2">
              <Link to="#">Documentation</Link>
            </li>
            <li className="list-inline-item px-0 px-sm-2">
              <Link to="#">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
    : null
  );
}
