/* eslint-disable no-unused-vars */
import jwt from "jwt-decode";
import {parseJwt} from "./TokenUtilization";

const role_authority = require('../domain/roles.json')

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
};

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    //var decoded = jwt.decode(user?.accessToken);
    return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    var decoded = jwt.decode(user?.accessToken);
    localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
    const datalar = parseJwt(user?.access_token)
    if (datalar.tenant) {
        localStorage.setItem("selectedTenant", JSON.stringify(datalar.tenant));
    }

    localStorage.setItem("user_data", JSON.stringify(datalar));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("authority", JSON.stringify({authorities: role_authority[datalar.role]}));
};

const removeUser = () => {
    localStorage.removeItem("user");
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
};

export default TokenService;
