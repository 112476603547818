import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import PageNavigation from "../components/PageNavigation";
import ExamResults from "./ExamResults";

export default function ApplicationResult (){

    const [examList, setExamList] = useState([]);

    const [selectedExamId, setSelectedExamId] = useState(null);

    useEffect(() => {
        ExamService.getAllExam().then((response) => {
            setExamList(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <React.Fragment>

            <PageNavigation main="Componen List" sub="Home" header="Application List" description="List of exams"/>
            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">

                <select
                    className="form-select w-100"
                    aria-label="Message select example" onChange={(e) => setSelectedExamId(e.target.value)}>
                    <option value="0" selected>Select...</option>
                    {
                        examList.map((exam, key) =>
                            <option value={exam.id} selected>{exam.name}</option>)
                    }
                </select>
                <hr/>
                {
                    selectedExamId ? <ExamResults examId={selectedExamId}/> : null
                }
            </div>
        </React.Fragment>
    )
}