/* eslint-disable no-unused-vars */
import axios from "axios";
import {NotificationManager} from "react-notifications";

const siteConfig = require("../../configs/config.json");

export default async function Request(method, url, body) {
    const user = JSON.parse(localStorage.getItem("user"));

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            Authorization: `Bearer ${user && user.access_token ? user.access_token : null}`,
        },
    };

    const errorMessageHandler = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    NotificationManager.error('Invalid request', 'Error', 3000);
                    break;
                case 401:
                    NotificationManager.error('Unauthorized access', 'Error', 3000);
                    localStorage.removeItem('user');
                    window.location.href = '/sing-in';
                    break;
                case 403:
                    NotificationManager.error('Access denied', 'Error', 3000);
                    break;
                case 404:
                    NotificationManager.error('Resource not found', 'Error', 3000);
                    break;
                case 500:
                    NotificationManager.error('Server error', 'Error', 3000);
                    break;
                default:
                    NotificationManager.error(`Unexpected error: ${error.response.status}`, 'Error', 3000);
            }
        } else if (error.request) {
            NotificationManager.error('Network error - Unable to reach server', 'Error', 3000);
            localStorage.removeItem("user_data");
            localStorage.removeItem("user");
            localStorage.removeItem("authority");

        } else {
            NotificationManager.error('Request failed: ' + error.message, 'Error', 3000);
        }
        return `Error: ${error}`;
    }

    if (method === "get") {
        try {
            const res = await axios.get(siteConfig.api.invokeUrl + url, config);
            return res.data;
        } catch (error) {
            errorMessageHandler(error);
            return null;
        }

    } else if (method === "patch") {
        try {
            const res = await axios.patch(siteConfig.api.invokeUrl + url, body, config);
            return res.data;
        } catch (error) {
            errorMessageHandler(error);
            return null;
        }
    } else if (method === "delete") {
        try {
            const res = await axios.delete(siteConfig.api.invokeUrl + url, config);
            return res.data;
        } catch (error) {
            errorMessageHandler(error);
            return null;
        }
    } else if (method === "post") {
        try {
            const res = await axios.post(siteConfig.api.invokeUrl + url, body, config);
            return res.data;
        } catch (error) {
            errorMessageHandler(error);
            return null;
        }
    } else if (method === "put") {
        try {
            const res = await axios.put(siteConfig.api.invokeUrl + url, body, config);
            return res.data;
        } catch (error) {
            errorMessageHandler(error);
            return null;
        }
    }
};