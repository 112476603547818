import React from "react";
import { IconListOpen } from "./Icons";

export default function ButtonList(props) {
  return (
    <div className="mb-3 mb-md-0">
      <button
        type="button"
        className="btn btn-gray-800 d-inline-flex alignpitems-center dropdown-toggle arrow-none"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {props.selectedText}
        <IconListOpen />
      </button>
      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
        {props.items
          ? props.items.map((item, key) =>
              item.sep ? (
                <div
                  key={key}
                  role="separator"
                  className="dropdown-divider my-1"
                ></div>
              ) : (
                <button
                key={key}
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => item.event(item.text)}
                >
                  {item.icon}
                  {item.text}
                </button>
              )
            )
          : null}
      </div>
    </div>
  );
}
