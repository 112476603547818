import PageNavigation from "./components/PageNavigation";
import {Link} from "react-router-dom";
import React from "react";

export default function LiveLesson() {


    const examList = [
        {
            "id": 0,
            "name": "Are you a Risk Taker",
            "explain": "Grammar explanation",
            "description": "Do you know how to use comparative adjectives like older, better and more interesting? Test what you know with interactive exercises and read the explanation to help you.",
            "date": "1 Jan 2024 18.00",
            "background": true,
            "iconColor": ""
        },
        {
            "id": 1,
            "name": "Are we killing or promoting creativity?",
            "explain": "Adjectives with one syllable",
            "description": "Comparative adjectives are used to compare differences between the two objects they modify (larger, smaller, faster, higher). They are used in sentences where two nouns are compared, in this pattern:",
            "date": "5 Jan 2024 18.00",
            "background": true,
            "iconColor": ""
        },
        {
            "id": 2,
            "name": "Cities",
            "explain": "Modals, the imperative, etc .",
            "description": "Superlative adjectives are used to describe an object which is at the upper or lower limit of a quality (the tallest, the smallest, the fastest, the highest). They are used in sentences where a subject is compared to a group of objects.",
            "date": "22 Jan 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 3,
            "name": "Crime and Punishment",
            "explain": "Conditionals, if, wish, etc.",
            "description": "Forming comparatives and superlatives is easy. The form depends on the number of syllables in the original adjective.",
            "date": "20 Jan 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 4,
            "name": "Customs and Traditions in Spain",
            "explain": "-ing and the infinitive",
            "description": "Adjectives with two syllables can form the comparative either by adding -er or by preceeding the adjective with more. These adjectives form the superlative either by adding -est or by preceeding the adjective with most.",
            "date": "5 Feb 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 5,
            "name": "Education is the most powerful weapon you can use to change the world",
            "explain": "Passive",
            "description": "Add -er for the comparative and -est for the superlative. If the adjective has a consonant + single vowel + consonant spelling, the final consonant must be doubled before adding the ending.",
            "date": "12 Feb 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 6,
            "name": "Food: Food for Thought Issues",
            "explain": "Irregular comparatives and superlatives",
            "description": "Adjectives with three or more syllables form the comparative by putting more in front of the adjective, and the superlative by putting most in front.",
            "date": "19 Feb 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 7,
            "name": "-ed –ing Adjectives: The Supporting Act",
            "explain": "Articles, nouns, pronouns, and determiners.",
            "description": "These very common adjectives have completely irregular comparative and superlative forms.",
            "date": "26 Feb 2024 18.00",
            "background": false,
            "iconColor": ""
        },
        {
            "id": 8,
            "name": "The Environment: Save Mother Earth",
            "explain": "Relative clauses, relative pronouns and adverbs",
            "description": "If you are not sure whether a two-syllable adjective can take a comparative or superlative ending, play it safe and use more and most instead. For adjectives ending in y, change the y to an i before adding the ending.",
            "date": "4 March 2024 18.00",
            "background": false,
            "iconColor": ""
        },

    ]


    const examRow = () => {

        return (
            examList && Array.isArray(examList) ?
                examList.map((exam, key) =>
                        <tr>
                            <td>
                                <Link to="#" className="d-flex align-items-center"
                                >
                                    <div
                                        className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                                    >
                                        <span>{exam.id}</span>
                                    </div>
                                    <div className="d-block">
                                        <span className="fw-bold">{exam.date}</span>
                                        <div className="small text-gray">
                                            <span className="__cf_email__">{exam.description}</span>
                                        </div>
                                    </div>
                                </Link>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}><span className="fw-normal">  <Link
                                to="https://zoom.us/" target="_blank"
                                className="btn btn-secondary d-inline-flex align-items-center me-2 dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                            <svg
                                className="icon icon-xs me-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                ></path>
                            </svg>
                            Connect Zoom Application
                        </Link></span></td>
                            <td>

                            </td>

                        </tr>
                )
                : null
        )
    }

    return (
        <React.Fragment>

            <PageNavigation main="Componen List" sub="Home" header="Live Lessons" description="Connect" link="/"
                            linkName="Detail"/>
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            >
                <div>

                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-gray-800 d-inline-flex align-items-center me-2"
                    >
                        <svg
                            className="icon icon-xs"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <button
                        className="btn btn-gray-800 d-inline-flex align-items-center dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <svg
                            className="icon icon-xs me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                            <path
                                fillRule="evenodd"
                                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        Reports
                        <svg
                            className="icon icon-xs ms-1"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>


            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">

                <table className="table user-table table-hover align-items-center">
                    <thead>
                    <tr>
                        <th className="border-bottom">Name</th>
                        <th className="border-bottom">Link</th>


                    </tr>
                    </thead>
                    <tbody>

                    {
                        examRow()
                    }

                    </tbody>
                </table>
            </div>

        </React.Fragment>
    )
}