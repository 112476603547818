import PageNavigation from "./components/PageNavigation";
import {Link} from "react-router-dom";
import React from "react";

export default function Message (){

    return(
        <React.Fragment>



            <PageNavigation main="Componen List" sub="Home" header="Message" description="List of components" link="/" linkName="Detail" />


            <div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
                <div>
                    <Link
                        to="/"
                        className="btn btn-secondary d-inline-flex align-items-center mb-3 mb-md-0"
                    ><svg
                        className="icon icon-xs me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                        ></path>
                        <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                        Compose</Link>
                </div>
                <div className="d-block d-sm-flex">
                    <div className="btn-group mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
                        <button className="btn btn-gray-800">
                            <svg
                                className="icon icon-xs text-white"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                                <path
                                    fillRule="evenodd"
                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                        <button className="btn btn-gray-800 text-white">
                            <svg
                                className="icon icon-xs text-white"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                        <button className="btn btn-gray-800 text-white">
                            <svg
                                className="icon icon-xs text-white"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className="mb-3 mb-md-0">
                        <button
                            type="button"
                            className="btn btn-gray-800 d-inline-flex alignpitems-center dropdown-toggle arrow-none"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            More
                            <svg
                                className="icon icon-xs ms-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                        <div
                            className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                        >
                            <Link className="dropdown-item d-flex align-items-center" href="#"
                            ><svg
                                className="dropdown-icon text-gray-400 me-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                                <path
                                    fillRule="evenodd"
                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                                Archive </Link
                            ><Link className="dropdown-item d-flex align-items-center" href="#"
                        ><svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                            Mark as read </Link
                        ><Link className="dropdown-item d-flex align-items-center" href="#"
                        ><svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                            Snooze</Link
                        >
                            <div role="separator" className="dropdown-divider my-1"></div>
                            <Link className="dropdown-item d-flex align-items-center" href="#"
                            ><svg
                                className="dropdown-icon text-danger me-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                                Remove</Link
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div className="message-wrapper border-0 bg-white shadow rounded mb-4">
                <div className="card hover-state border-bottom rounded-0 rounded-top py-3">
                    <div
                        className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
                    >
                        <div className="col-1 align-items-center px-0 d-none d-lg-flex">
                            <div className="form-check inbox-check me-2 mb-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="mailCheck1"
                                />
                                <label className="form-check-label" for="mailCheck1"></label>
                            </div>
                            <svg
                                className="icon icon-sm rating-star d-none d-lg-inline-block"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                ></path>
                            </svg>
                        </div>
                        <div className="col-10 col-lg-2 ps-0 ps-lg-3 pe-lg-3">
                            <Link href="#" className="d-flex align-items-center"
                            ><img
                                src="../assets/img/team/profile-picture-1.jpg"
                                className="avatar-sm rounded-circle me-3"
                                alt="Avatar"
                            />
                                <span className="h6 fw-bold mb-0">Roy Fendley</span></Link
                            >
                        </div>
                        <div
                            className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
                        >
                            <div className="text-muted small d-none d-lg-block">11:01 AM</div>
                            <div className="dropdown ms-3">
                                <button
                                    type="button"
                                    className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <svg
                                        className="icon icon-xs"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                                        ></path>
                                    </svg>
                                </button>
                                <div
                                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                                >
                                    <Link className="dropdown-item d-flex align-items-center" href="#"
                                    ><svg
                                        className="dropdown-icon text-gray-400 me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                                        <path
                                            fillRule="evenodd"
                                            d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                        Archive </Link
                                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                                ><svg
                                    className="dropdown-icon text-gray-400 me-2"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                    Mark as read </Link
                                ><Link className="dropdown-item d-flex align-items-center" href="#"
                                ><svg
                                    className="dropdown-icon text-gray-400 me-2"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                    Snooze</Link
                                >
                                    <div role="separator" className="dropdown-divider my-1"></div>
                                    <Link className="dropdown-item d-flex align-items-center" href="#"
                                    ><svg
                                        className="dropdown-icon text-danger me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                        Remove</Link
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
                        >
                            <Link
                                href="single-message.html"
                                className="fw-normal text-gray-600-900 truncate-text"
                            ><span className="fw-bold ps-lg-5"
                            >Long time no see!!!</span
                            >
                                </Link
                            >
                        </div>
                    </div>
                </div>

                <div className="row p-4">
                    <div className="col-7 mt-1">Showing 1 - 20 of 289</div>
                    <div className="col-5">
                        <div className="btn-group float-end">
                            <Link href="#" className="btn btn-gray-100"
                            ><svg
                                className="icon icon-sm"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                ></path>
                            </svg> </Link
                            ><Link href="#" className="btn btn-gray-800"
                        ><svg
                            className="icon icon-sm"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                            ></path></svg
                        ></Link>
                        </div>
                    </div>
                </div>
            </div>



            <PageNavigation main="Componen List" sub="Home" header="Message Detail" description="List of components" link="/" linkName="Detail" />
            <div className="row justify-content-center mt-3">
                <div
                    className="col-12 d-flex justify-content-between flex-column flex-sm-row mt-4"
                >
                    <Link
                        className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0"
                        href="messages.html"
                    ><svg
                        className="icon icon-xs me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
                        ></path>
                        <path
                            d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
                        ></path>
                    </svg>
                        Back to Messages</Link
                    >
                    <p className="text-muted fw-normal small">
                        Messages starting from 10th of June, 2020
                    </p>
                </div>
                <div className="col-12">
                    <div className="card border-0 shadow p-4 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                  className="avatar-sm img-fluid rounded-circle me-2"
                  src="../assets/img/team/profile-picture-1.jpg"
                  alt="avatar"
              />
                  <span className="fw-bold">Neil Sims</span> </Link
              ><span className="fw-normal ms-2">March 26, 19:25</span></span
              >
                            <div className="d-none d-sm-block">
                                <svg
                                    className="icon icon-xs"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <p className="m-0">
                            Hi Chris! Thanks a lot for this very useful homework. Saved me a
                            lot of time and searches on the internet.
                        </p>
                    </div>
                    <div
                        className="card bg-gray-800 text-white border-0 shadow p-4 ms-md-5 ms-lg-6 mb-4"
                    >
                        <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><span className="fw-bold">Your Answer</span>
                <span className="fw-normal text-gray-300 ms-2"
                >March 26, 19:25</span
                ></span
              >
                            <div className="d-none d-sm-block">
                                <svg
                                    className="icon icon-xs"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <p className="text-gray-300 m-0">
                            Hi Neil, thanks for sharing your thoughts regarding Spaces. Hi
                            Neil, thanks for sharing your thoughts regarding Spaces.
                        </p>
                    </div>
                    <div className="card border-0 shadow p-4 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                  className="avatar-sm img-fluid rounded-circle me-2"
                  src="../assets/img/team/profile-picture-1.jpg"
                  alt="avatar"
              />
                  <span className="fw-bold">Neil Sims</span> </Link
              ><span className="ms-2">March 26, 19:25</span></span
              >
                            <div className="d-none d-sm-block">
                                <svg
                                    className="icon icon-xs"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <p className="m-0">
                            Hi Chris! Thanks a lot for this very useful template. Saved me a
                            lot of time and searches on the internet.
                        </p>
                    </div>
                    <div className="card border-0 shadow p-4 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                  className="avatar-sm img-fluid rounded-circle me-2"
                  src="../assets/img/team/profile-picture-1.jpg"
                  alt="avatar"
              />
                  <span className="fw-bold">Neil Sims</span> </Link
              ><span className="ms-2">March 26, 19:25</span></span
              >
                            <div className="d-none d-sm-block">
                                <div className="d-none d-sm-block">
                                    <svg
                                        className="icon icon-xs"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="m-0">
                            Hi Chris! Thanks a lot for this very useful template. Saved me a
                            lot of time and searches on the internet.
                        </p>
                    </div>
                    <div
                        className="card bg-gray-800 text-white border-0 shadow p-4 ms-md-5 ms-lg-6 mb-4"
                    >
                        <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><span className="fw-bold">Your Answer</span>
                <span className="ms-2">March 26, 19:25</span></span
              >
                            <div className="d-none d-sm-block">
                                <div className="d-none d-sm-block">
                                    <svg
                                        className="icon icon-xs"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="text-gray-300 m-0">
                            Hi Neil, thanks for sharing your thoughts regarding Spaces. Hi
                            Neil, thanks for sharing your thoughts regarding Spaces.
                        </p>
                    </div>
                    <form action="#" className="mt-4 mb-5">
            <textarea
                className="form-control border-0 shadow mb-4"
                id="message"
                placeholder="Your Message"
                rows="6"
                maxlength="1000"
                required
            ></textarea>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div className="file-field">
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <svg
                                            className="icon icon-md text-gray-400 me-3"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                        <input type="file" />
                                        <div className="d-block text-left d-sm-block">
                                            <div className="fw-normal text-dark mb-lg-1">Attach File</div>
                                            <div
                                                className="text-gray small pe-3 pe-lg-11 d-none d-md-inline"
                                            >
                                                Supported files are: jpg, jpeg, png, doc, pdf, gif, zip,
                                                rare, tar, txt, xls, docx, xlsx, odt
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-secondary d-inline-flex align-items-center text-dark"
                                >
                                    <svg
                                        className="icon icon-xs me-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    Reply
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </React.Fragment>
    )
}