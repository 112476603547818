import React from "react";
import PageNavigation from "./components/PageNavigation";
import { Link } from "react-router-dom";
export default function ComponentsList() {
  return (
    <React.Fragment>
      <PageNavigation main="Componen List" sub="Home" header="Kanban" description="List of components" link="/" linkName="Detail" />

      <div className="container-fluid kanban-container py-4 px-0">
        <div className="row d-flex flex-nowrap">
          <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fs-6 fw-bold mb-0">To do</h5>
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  </svg>
                </button>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"></path>
                    </svg>
                    Add Card
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                      <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                    </svg>
                    Copy List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Move List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Watch
                  </Link>
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove
                  </Link>
                </div>
              </div>
            </div>
            <div id="kanbanColumn1" className="list-group kanban-list">
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">variables.scss problems</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">3 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Alexander Smith"
                      data-bs-original-title="Alexander Smith"
                      title="Alexander Smith"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-2.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">Redesign homepage</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <img
                    src="../assets/img/themesberg-mockup.jpg"
                    className="card-img-top mb-2 mb-lg-3"
                    alt="themesberg marketplace"
                  />
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">2 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
                  <h3 className="h5 mb-0">variables.scss problems</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">3 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Alexander Smith"
                      data-bs-original-title="Alexander Smith"
                      title="Alexander Smith"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-2.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center justify-content-center dashed-outline new-card w-100"
                data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                Add another card
              </button>
            </div>
            <div className="d-grid">
              <div
                className="modal fade"
                id="KanbanAddCard"
                tabindex="-1"
                aria-labelledby="KanbanAddCardLabel4"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-3">
                    <div className="modal-header pb-0 border-0">
                      <h5
                        className="modal-title fw-normal"
                        id="KanbanAddCardLabel4"
                      >
                        Add a new task
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter a title for this card…"
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Enter a description for this card…"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer border-0 pt-0 justify-content-start">
                      <button
                        type="button"
                        className="btn btn-outline-gray-500"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <svg
                          className="icon icon-xs me-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          ></path>
                        </svg>
                        Add card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fs-6 fw-bold mb-0">In progress</h5>
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  </svg>
                </button>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"></path>
                    </svg>
                    Add Card
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                      <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                    </svg>
                    Copy List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Move List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Watch
                  </Link>
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove
                  </Link>
                </div>
              </div>
            </div>
            <div id="kanbanColumn2" className="list-group kanban-list">
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">Redesign homepage</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <img
                    src="../assets/img/themesberg-mockup.jpg"
                    className="card-img-top mb-2 mb-lg-3"
                    alt="themesberg marketplace"
                  />
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">2 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">Design banner</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h5 className="h6 mb-0">Progress</h5>
                    <div className="fw-bold small">
                      <span>40%</span>
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "75%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <h5 className="fs-6 fw-normal mt-4">2 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center justify-content-center dashed-outline new-card w-100"
                data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                Add another card
              </button>
            </div>
            <div className="d-grid">
              <div
                className="modal fade"
                id="KanbanAddCard2"
                tabindex="-1"
                aria-labelledby="KanbanAddCardLabel2"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-4">
                    <div className="modal-header pb-0 border-0">
                      <h5
                        className="modal-title fw-normal"
                        id="KanbanAddCardLabel2"
                      >
                        Add a new task
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput2"
                          placeholder="Enter a title for this card…"
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea2"
                          placeholder="Enter a description for this card…"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer border-0 pt-0 justify-content-start">
                      <button
                        type="button"
                        className="btn btn-outline-gray-500"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <svg
                          className="icon icon-xs me-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          ></path>
                        </svg>
                        Add card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fs-6 fw-bold mb-0">Done</h5>
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  </svg>
                </button>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"></path>
                    </svg>
                    Add Card
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                      <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                    </svg>
                    Copy List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Move List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Watch
                  </Link>
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove
                  </Link>
                </div>
              </div>
            </div>
            <div id="kanbanColumn3" className="list-group kanban-list">
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
                  <h3 className="h5 mb-0">variables.scss problems</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">3 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Alexander Smith"
                      data-bs-original-title="Alexander Smith"
                      title="Alexander Smith"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-2.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">Redesign homepage</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <img
                    src="../assets/img/themesberg-mockup.jpg"
                    className="card-img-top mb-2 mb-lg-3"
                    alt="themesberg marketplace"
                  />
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">2 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center justify-content-center dashed-outline new-card w-100"
                data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                Add another card
              </button>
            </div>
            <div className="d-grid">
              <div
                className="modal fade"
                id="KanbanAddCard3"
                tabindex="-1"
                aria-labelledby="KanbanAddCardLabel3"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content p-4">
                    <div className="modal-header pb-0 border-0">
                      <h5
                        className="modal-title fw-normal"
                        id="KanbanAddCardLabel3"
                      >
                        Add a new task
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput3"
                          placeholder="Enter a title for this card…"
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea3"
                          placeholder="Enter a description for this card…"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer border-0 pt-0 justify-content-start">
                      <button
                        type="button"
                        className="btn btn-outline-gray-500"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary d-inline-flex align-items-center"
                      >
                        <svg
                          className="icon icon-xs me-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          ></path>
                        </svg>
                        Add card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fs-6 fw-bold mb-0">Deployed</h5>
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  </svg>
                </button>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"></path>
                    </svg>
                    Add Card
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                      <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                    </svg>
                    Copy List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Move List
                  </Link>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Watch
                  </Link>
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#">
                    <svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove
                  </Link>
                </div>
              </div>
            </div>
            <div id="kanbanColumn4" className="list-group kanban-list">
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-2">
                  <h3 className="h5 mb-0">variables.scss problems</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">3 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Alexander Smith"
                      data-bs-original-title="Alexander Smith"
                      title="Alexander Smith"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-2.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card border-0 shadow p-4">
                <div className="card-header d-flex align-items-center justify-content-between border-0 p-0 mb-3">
                  <h3 className="h5 mb-0">Redesign homepage</h3>
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          className="icon icon-xs text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                          </svg>
                          Edit Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
                            <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"></path>
                          </svg>
                          Copy Task
                        </Link>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-gray-400 me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-1"
                        ></div>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <svg
                            className="dropdown-icon text-danger me-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Remove
                        </Link>
                      </div>
                      <div
                        className="dropdown-menu dashboard-dropdown dropdown-menu-start py-0"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <Link
                          className="dropdown-item fw-normal rounded-top"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#editTaskModal"
                        >
                          <span className="fas fa-edit"></span>Edit task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-clone"></span>Copy Task
                        </Link>
                        <Link className="dropdown-item fw-normal" href="#">
                          <span className="far fa-star"></span> Add to favorites
                        </Link>
                        <div
                          role="separator"
                          className="dropdown-divider my-0"
                        ></div>
                        <Link
                          className="dropdown-item fw-normal text-danger rounded-bottom"
                          href="#"
                        >
                          <span className="fas fa-trash-alt"></span>Remove
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <img
                    src="../assets/img/themesberg-mockup.jpg"
                    className="card-img-top mb-2 mb-lg-3"
                    alt="themesberg marketplace"
                  />
                  <p>
                    On line 672 you define $table_variants. Each instance of
                    "color-level" needs to be changed to "shift-color".
                  </p>
                  <h5 className="fs-6 fw-normal mt-4">2 Assignees</h5>
                  <div className="avatar-group">
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                      data-bs-original-title="Ryan Tompson"
                      title="Ryan Tompson"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-1.jpg"
                      />
                    </Link>
                    <Link
                      href="#"
                      className="avatar"
                      data-bs-toggle="tooltip"
                      data-original-title="Bonnie Green"
                      data-bs-original-title="Bonnie Green"
                      title="Bonnie Green"
                    >
                      <img
                        className="rounded"
                        alt="placeholder"
                        src="../assets/img/team/profile-picture-3.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center justify-content-center dashed-outline new-card w-100"
                data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                Add another card
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
            <div id="kanbanColumn5" className="list-group"></div>
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center justify-content-center dashed-outline new-card w-100"
                data-bs-toggle="modal"
                data-bs-target="#KanbanAddCard"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                Add another card
              </button>
              <div
                className="modal fade"
                id="KanbanAddGroup5"
                tabindex="-1"
                aria-labelledby="KanbanAddGroupLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content p-4">
                    <div className="modal-header pb-0 border-0">
                      <h5
                        className="modal-title fw-normal"
                        id="KanbanAddGroupLabel"
                      >
                        Add a new group
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body pb-0">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput4"
                          placeholder="Enter a title for this group"
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea4"
                          placeholder="Enter a description for this group"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer border-0 pt-0 justify-content-start">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-success">
                        <span className="fas fa-plus me-2"></span>Add group
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="editTaskModal"
          tabindex="-1"
          aria-labelledby="editTaskModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content p-lg-3">
              <div className="modal-header align-items-start border-bottom">
                <div className="d-block">
                  <h2 className="h5 mb-3">variables.scss problems</h2>
                  <div className="d-flex">
                    <div className="d-block me-3 me-sm-4">
                      <h5
                        className="fs-6 fw-bold text-gray-500"
                        id="editTaskModalLabel"
                      >
                        Members
                      </h5>
                      <div className="d-flex align-items-center">
                        <Link
                          href="#"
                          className="avatar"
                          data-bs-toggle="tooltip"
                          data-original-title="Ryan Tompson"
                          data-bs-original-title=""
                          title=""
                        >
                          <img
                            className="rounded"
                            alt="placeholder"
                            src="../assets/img/team/profile-picture-1.jpg"
                          />
                        </Link>
                        <Link
                          href="#"
                          className="avatar me-1"
                          data-bs-toggle="tooltip"
                          data-original-title="Romina Hadid"
                          data-bs-original-title=""
                          title=""
                        >
                          <img
                            className="rounded"
                            alt="placeholder"
                            src="../assets/img/team/profile-picture-2.jpg"
                          />
                        </Link>
                        <button className="btn btn-icon btn-sm btn-gray-200 d-inline-flex align-items-center">
                          <svg
                            className="icon icon-xs"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="d-block me-3">
                      <h5 className="fs-6 fw-bold text-gray-500">Labels</h5>
                      <div className="d-flex align-items-center">
                        <Link
                          href="#"
                          className="badge bg-success text-white rounded py-2 px-3 me-1"
                        >
                          Design
                        </Link>
                        <button className="btn btn-sm btn-gray-200 d-inline-flex align-items-center">
                          <svg
                            className="icon icon-xs"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-4">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <div className="row mb-4">
                      <div className="col-auto">
                        <div className="border border-3 rounded mb-2">
                          <img
                            className="image-sm rounded"
                            src="../assets/img/team/profile-picture-1.jpg"
                            alt="profile"
                          />
                        </div>
                        <div className="text-center">
                          <Link href="#" className="me-2">
                            <svg
                              className="icon icon-xs"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                              ></path>
                            </svg>
                          </Link>
                          <Link href="#">
                            <svg
                              className="icon icon-xs"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                              ></path>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                              ></path>
                            </svg>
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <form>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea5"
                            placeholder="Leave a comment"
                            rows="3"
                          ></textarea>
                        </form>
                      </div>
                    </div>
                    <div className="row mb-4 mb-lg-0">
                      <div className="col-12 mb-4">
                        <div className="bg-gray-50 border border-gray-100 rounded p-3">
                          <div className="d-flex align-items-center mb-2">
                            <h3 className="fs-6 mb-0 me-3">Bonnie Green</h3>
                            <small>32 minutes ago</small>
                          </div>
                          <p className="text-dark mb-1">
                            Pixel Pro is a premium Bootstrap 5 UI Kit without
                            jQuery featuring over 1000 components, 50+ sections
                            and 35 example pages including a fully fledged user
                            dashboard.
                          </p>
                          <Link
                            className="text-gray-700 hover:underline small"
                            href="#"
                          >
                            Edit
                          </Link>
                          &middot;
                          <Link
                            className="text-gray-700 hover:underline small"
                            href="#"
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="bg-gray-50 border border-gray-100 rounded p-3">
                          <div className="d-flex align-items-center mb-2">
                            <h3 className="fs-6 mb-0 me-3">Roy Fendley</h3>
                            <small>1 hour ago</small>
                          </div>
                          <p className="text-dark mb-1">
                            Pixel Pro is a premium Bootstrap 5 UI Kit without
                            jQuery featuring over 1000 components, 50+ sections
                            and 35 example pages including a fully fledged user
                            dashboard.
                          </p>
                          <Link
                            className="text-gray-700 hover:underline small"
                            href="#"
                          >
                            Edit
                          </Link>
                          &middot;
                          <Link
                            className="text-gray-700 hover:underline small"
                            href="#"
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="d-grid gap-2">
                      <Link
                        href="#"
                        className="btn btn-sm btn-gray-200 d-inline-flex align-items-center rounded py-1 ps-3 text-start"
                      >
                        <svg
                          className="icon icon-xxs text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                        </svg>
                        Members
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-sm btn-gray-200 d-inline-flex align-items-center rounded py-1 ps-3 text-start"
                      >
                        <svg
                          className="icon icon-xxs text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Labels
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-sm btn-gray-200 d-inline-flex align-items-center rounded py-1 ps-3 text-start"
                      >
                        <svg
                          className="icon icon-xxs text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                          <path
                            fillRule="evenodd"
                            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Checklist
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-sm btn-gray-200 d-inline-flex align-items-center rounded py-1 ps-3 text-start"
                      >
                        <svg
                          className="icon icon-xxs text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Attachment
                      </Link>
                      <Link
                        href="#"
                        className="btn btn-sm btn-gray-200 d-inline-flex align-items-center rounded py-1 ps-3 text-start"
                      >
                        <svg
                          className="icon icon-xxs text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Due Date
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start border-top">
                <div className="d-none d-sm-flex">
                  <Link
                    href="#"
                    className="btn btn-gray-800 d-inline-flex align-items-center me-2"
                  >
                    <svg
                      className="icon icon-xs text-gray-300 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Move
                  </Link>
                  <Link
                    href="#"
                    className="btn btn-gray-800 d-inline-flex align-items-center me-2"
                  >
                    <svg
                      className="icon icon-xs text-gray-300 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive
                  </Link>
                  <Link
                    href="#"
                    className="btn btn-gray-800 d-inline-flex align-items-center me-2"
                  >
                    <svg
                      className="icon icon-xs text-gray-300 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Watch
                    <svg
                      className="icon icon-xxs text-success ms-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                  <Link
                    href="#"
                    className="btn btn-gray-800 d-inline-flex align-items-center me-2"
                  >
                    <svg
                      className="icon icon-xxs text-gray-300 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"></path>
                    </svg>
                    Share
                  </Link>
                </div>
                <div className="col-12 d-grid gap-2 d-sm-none">
                  <Link href="#" className="btn btn-gray-800 me-2 text-start">
                    <span className="fas fa-arrow-right me-2"></span>Move
                  </Link>
                  <Link href="#" className="btn btn-gray-800 me-2 text-start">
                    <span className="fas fa-archive me-2"></span>Archive
                  </Link>
                  <Link href="#" className="btn btn-gray-800 me-2 text-start">
                    <span className="fas fa-eye me-2"></span>Watch
                    <span className="fas fa-check-circle ms-3 text-success"></span>
                  </Link>
                  <Link href="#" className="btn btn-gray-800 me-2 text-start">
                    <span className="fas fa-share-alt me-2"></span>Share
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageNavigation main="Componen List" sub="Home" header="Message" description="List of components" link="/" linkName="Detail" />


      <div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
        <div>
          <Link
            to="/"
            className="btn btn-secondary d-inline-flex align-items-center mb-3 mb-md-0"
          ><svg
            className="icon icon-xs me-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
              <path
                d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
              ></path>
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              ></path>
            </svg>
            Compose</Link>
        </div>
        <div className="d-block d-sm-flex">
          <div className="btn-group mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
            <button className="btn btn-gray-800">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                <path
                  fillRule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <button className="btn btn-gray-800 text-white">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <button className="btn btn-gray-800 text-white">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="mb-3 mb-md-0">
            <button
              type="button"
              className="btn btn-gray-800 d-inline-flex alignpitems-center dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              More
              <svg
                className="icon icon-xs ms-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
            >
              <Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                  <path
                    fillRule="evenodd"
                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Archive </Link
              ><Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Mark as read </Link
              ><Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Snooze</Link
              >
              <div role="separator" className="dropdown-divider my-1"></div>
              <Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-danger me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Remove</Link
              >
            </div>
          </div>
        </div>
      </div>
      <div className="message-wrapper border-0 bg-white shadow rounded mb-4">
        <div className="card hover-state border-bottom rounded-0 rounded-top py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3 pe-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-1.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Roy Fendley</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">11:01 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600-900 truncate-text"
              ><span className="fw-bold ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="fw-bold d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-3.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Bonnie Green</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">10:23 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-900 truncate-text"
              ><span className="fw-bold ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="fw-bold d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><div
                className="avatar-sm bg-secondary d-flex align-items-center justify-content-center rounded me-3"
              >
                  <span className="small fw-bold">SA</span>
                </div>
                <span className="h6 fw-bold mb-0">Scott Anderson</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">10:00 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-4.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Ronnie Buchanan</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">07:45 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-3.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Jane Rinehart</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">07:30 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><div
                className="avatar-sm bg-purple d-flex align-items-center justify-content-center rounded me-3"
              >
                  <span className="small text-white fw-bold">JG</span>
                </div>
                <span className="h6 fw-bold mb-0">John Ginther</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">06:10 AM</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-6.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">George Driskell</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 14</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><div
                className="avatar-sm bg-purple d-flex align-items-center justify-content-center rounded me-3"
              >
                  <span className="small text-white fw-bold">JB</span>
                </div>
                <span className="h6 fw-bold mb-0">John Benny</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 14</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-4.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Ronnie Buchanan</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 15</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-5.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Flora Maresca</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 15</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><div
                className="avatar-sm bg-warning d-flex align-items-center justify-content-center rounded me-3"
              >
                  <span className="small text-white fw-bold">T</span>
                </div>
                <span className="h6 fw-bold mb-0">Themesberg</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 17</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 align-items-center px-0 d-none d-lg-flex">
              <div className="form-check inbox-check me-2 mb-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
              <svg
                className="icon icon-sm rating-star d-none d-lg-inline-block"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                ></path>
              </svg>
            </div>
            <div className="col-10 col-lg-2 ps-0 ps-lg-3">
              <Link href="#" className="d-flex align-items-center"
              ><img
                  src="../assets/img/team/profile-picture-7.jpg"
                  className="avatar-sm rounded-circle me-3"
                  alt="Avatar"
                />
                <span className="h6 fw-bold mb-0">Jane Rinehart</span></Link
              >
            </div>
            <div
              className="col-2 col-lg-2 d-flex align-items-center justify-content-end px-0 order-lg-4"
            >
              <div className="text-muted small d-none d-lg-block">Jun 18</div>
              <div className="dropdown ms-3">
                <button
                  type="button"
                  className="btn btn-sm fs-6 px-1 py-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                      <path
                        fillRule="evenodd"
                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Archive </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Mark as read </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Snooze</Link
                  >
                  <div role="separator" className="dropdown-divider my-1"></div>
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Remove</Link
                  >
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 d-flex align-items-center mt-3 mt-lg-0 ps-0"
            >
              <Link
                href="single-message.html"
                className="fw-normal text-gray-600 truncate-text"
              ><span className="fw-normal ps-lg-5"
              >Long time no see - Can we help you set up email
                  forwarding?</span
                >
                <span className="d-none d-md-inline"
                >We’ve noticed you haven’t set up email forwarding and we
                  could help you</span
                ></Link
              >
            </div>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-7 mt-1">Showing 1 - 20 of 289</div>
          <div className="col-5">
            <div className="btn-group float-end">
              <Link href="#" className="btn btn-gray-100"
              ><svg
                className="icon icon-sm"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg> </Link
              ><Link href="#" className="btn btn-gray-800"
              ><svg
                className="icon icon-sm"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path></svg
                ></Link>
            </div>
          </div>
        </div>
      </div>



      <PageNavigation main="Componen List" sub="Home" header="Message Detail" description="List of components" link="/" linkName="Detail" />
      <div className="row justify-content-center mt-3">
        <div
          className="col-12 d-flex justify-content-between flex-column flex-sm-row mt-4"
        >
          <Link
            className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0"
            href="messages.html"
          ><svg
            className="icon icon-xs me-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
              <path
                d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
              ></path>
              <path
                d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
              ></path>
            </svg>
            Back to Messages</Link
          >
          <p className="text-muted fw-normal small">
            Messages starting from 10th of June, 2020
          </p>
        </div>
        <div className="col-12">
          <div className="card border-0 shadow p-4 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                    className="avatar-sm img-fluid rounded-circle me-2"
                    src="../assets/img/team/profile-picture-1.jpg"
                    alt="avatar"
                  />
                  <span className="fw-bold">Neil Sims</span> </Link
                ><span className="fw-normal ms-2">March 26, 19:25</span></span
              >
              <div className="d-none d-sm-block">
                <svg
                  className="icon icon-xs"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
            </div>
            <p className="m-0">
              Hi Chris! Thanks a lot for this very useful template. Saved me a
              lot of time and searches on the internet.
            </p>
          </div>
          <div
            className="card bg-gray-800 text-white border-0 shadow p-4 ms-md-5 ms-lg-6 mb-4"
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><span className="fw-bold">Your Answer</span>
                <span className="fw-normal text-gray-300 ms-2"
                >March 26, 19:25</span
                ></span
              >
              <div className="d-none d-sm-block">
                <svg
                  className="icon icon-xs"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
            </div>
            <p className="text-gray-300 m-0">
              Hi Neil, thanks for sharing your thoughts regarding Spaces. Hi
              Neil, thanks for sharing your thoughts regarding Spaces.
            </p>
          </div>
          <div className="card border-0 shadow p-4 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                    className="avatar-sm img-fluid rounded-circle me-2"
                    src="../assets/img/team/profile-picture-1.jpg"
                    alt="avatar"
                  />
                  <span className="fw-bold">Neil Sims</span> </Link
                ><span className="ms-2">March 26, 19:25</span></span
              >
              <div className="d-none d-sm-block">
                <svg
                  className="icon icon-xs"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  ></path>
                </svg>
              </div>
            </div>
            <p className="m-0">
              Hi Chris! Thanks a lot for this very useful template. Saved me a
              lot of time and searches on the internet.
            </p>
          </div>
          <div className="card border-0 shadow p-4 mb-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><Link href="#"
              ><img
                    className="avatar-sm img-fluid rounded-circle me-2"
                    src="../assets/img/team/profile-picture-1.jpg"
                    alt="avatar"
                  />
                  <span className="fw-bold">Neil Sims</span> </Link
                ><span className="ms-2">March 26, 19:25</span></span
              >
              <div className="d-none d-sm-block">
                <div className="d-none d-sm-block">
                  <svg
                    className="icon icon-xs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <p className="m-0">
              Hi Chris! Thanks a lot for this very useful template. Saved me a
              lot of time and searches on the internet.
            </p>
          </div>
          <div
            className="card bg-gray-800 text-white border-0 shadow p-4 ms-md-5 ms-lg-6 mb-4"
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="font-small"
              ><span className="fw-bold">Your Answer</span>
                <span className="ms-2">March 26, 19:25</span></span
              >
              <div className="d-none d-sm-block">
                <div className="d-none d-sm-block">
                  <svg
                    className="icon icon-xs"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <p className="text-gray-300 m-0">
              Hi Neil, thanks for sharing your thoughts regarding Spaces. Hi
              Neil, thanks for sharing your thoughts regarding Spaces.
            </p>
          </div>
          <form action="#" className="mt-4 mb-5">
            <textarea
              className="form-control border-0 shadow mb-4"
              id="message"
              placeholder="Your Message"
              rows="6"
              maxlength="1000"
              required
            ></textarea>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div className="file-field">
                <div className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <svg
                      className="icon icon-md text-gray-400 me-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <input type="file" />
                    <div className="d-block text-left d-sm-block">
                      <div className="fw-normal text-dark mb-lg-1">Attach File</div>
                      <div
                        className="text-gray small pe-3 pe-lg-11 d-none d-md-inline"
                      >
                        Supported files are: jpg, jpeg, png, doc, pdf, gif, zip,
                        rare, tar, txt, xls, docx, xlsx, odt
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-secondary d-inline-flex align-items-center text-dark"
                >
                  <svg
                    className="icon icon-xs me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Reply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>




      <PageNavigation main="Componen List" sub="Home" header="User List" description="List of components" link="/" linkName="Detail" />

      <div className="table-settings mb-4">
        <div className="row justify-content-between align-items-center">
          <div className="col-9 col-lg-8 d-md-flex">
            <div className="input-group me-2 me-lg-3 fmxw-300">
              <span className="input-group-text"
              ><svg
                className="icon icon-xs"
                x-description="Heroicon name: solid/search"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg> </span
              ><input
                type="text"
                className="form-control"
                placeholder="Search users"
              />
            </div>
            <select
              className="form-select fmxw-200 d-none d-md-inline"
              aria-label="Message select example 2"
            >
              <option selected="selected">All</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
              <option value="3">Pending</option>
              <option value="3">Cancelled</option>
            </select>
          </div>
          <div className="col-3 col-lg-4 d-flex justify-content-end">
            <div className="btn-group">
              <div className="dropdown me-1">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-sm"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div className="dropdown-menu dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">Show</span>
                  <Link
                    className="dropdown-item d-flex align-items-center fw-bold"
                    href="#"
                  >10
                    <svg
                      className="icon icon-xxs ms-auto"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path></svg></Link
                  ><Link className="dropdown-item fw-bold" href="#">20</Link>
                  <Link className="dropdown-item fw-bold rounded-bottom" href="#"
                  >30</Link
                  >
                </div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-sm"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-xs dropdown-menu-end pb-0"
                >
                  <span className="small ps-3 fw-bold text-dark">Show</span>
                  <Link
                    className="dropdown-item d-flex align-items-center fw-bold"
                    href="#"
                  >10
                    <svg
                      className="icon icon-xxs ms-auto"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path></svg></Link
                  ><Link className="dropdown-item fw-bold" href="#">20</Link>
                  <Link className="dropdown-item fw-bold rounded-bottom" href="#"
                  >30</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card card-body shadow border-0 table-wrapper table-responsive"
      >
        <div className="d-flex mb-3">
          <select
            className="form-select fmxw-200"
            aria-label="Message select example"
          >
            <option selected="selected">Bulk Action</option>
            <option value="1">Send Email</option>
            <option value="2">Change Group</option>
            <option value="3">Delete User</option>
          </select>
          <button className="btn btn-sm px-3 btn-secondary ms-3">Apply</button>
        </div>
        <table className="table user-table table-hover align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck55"
                  />
                  <label className="form-check-label" for="userCheck55"></label>
                </div>
              </th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Date Created</th>
              <th className="border-bottom">Verified</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck1"
                  />
                  <label className="form-check-label" for="userCheck1"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-1.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">Roy Fendley</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="2e474048416e4b564f435e424b004d4143"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-success me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-success">Active</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck2"
                  />
                  <label className="form-check-label" for="userCheck2"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><div
                  className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                >
                    <span>SA</span>
                  </div>
                  <div className="d-block">
                    <span className="fw-bold">Scott Anderson</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="177e79717857726f767a677b723974787a"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-success me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-success">Active</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck3"
                  />
                  <label className="form-check-label" for="userCheck3"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-2.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">George Driskell</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="e1888f878ea18499808c918d84cf828e8c"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-success me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-success">Active</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck4"
                  />
                  <label className="form-check-label" for="userCheck4"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-3.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">Bonnie Green</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="335a5d555c73564b525e435f561d505c5e"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-success me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-success">Active</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck5"
                  />
                  <label className="form-check-label" for="userCheck5"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-7.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">Ronnie Buchanan</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="6d04030b022d08150c001d0108430e0200"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-purple me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-purple">Pending</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck6"
                  />
                  <label className="form-check-label" for="userCheck6"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><div
                  className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                >
                    <span>JR</span>
                  </div>
                  <div className="d-block">
                    <span className="fw-bold">Jane Rinehart</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="82ebece4edc2e7fae3eff2eee7ace1edef"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-purple me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-purple">Pending</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck7"
                  />
                  <label className="form-check-label" for="userCheck7"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-4.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">William Ginther</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="31585f575e715449505c415d541f525e5c"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-purple me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-purple">Pending</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td>
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck8"
                  />
                  <label className="form-check-label" for="userCheck8"></label>
                </div>
              </td>
              <td>
                <Link href="#" className="d-flex align-items-center"
                ><img
                    src="../assets/img/team/profile-picture-5.jpg"
                    className="avatar rounded-circle me-3"
                    alt="Avatar"
                  />
                  <div className="d-block">
                    <span className="fw-bold">Margaret Dow</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="40292e262f002538212d302c256e232f2d"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td><span className="fw-normal">10 Feb 2020</span></td>
              <td>
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-danger me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td><span className="fw-normal text-danger">Suspended</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="border-0">
                <div className="form-check dashboard-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="userCheck9"
                  />
                  <label className="form-check-label" for="userCheck9"></label>
                </div>
              </td>
              <td className="border-0">
                <Link href="#" className="d-flex align-items-center"
                ><div
                  className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-purple text-white me-3"
                >
                    <span>MH</span>
                  </div>
                  <div className="d-block">
                    <span className="fw-bold">Michael Hopkins</span>
                    <div className="small text-gray">
                      <span
                        className="__cf_email__"
                        data-cfemail="157c7b737a55706d74786579703b767a78"
                      >[email&#160;protected]</span
                      >
                    </div>
                  </div></Link
                >
              </td>
              <td className="border-0">
                <span className="fw-normal">10 Feb 2020</span>
              </td>
              <td className="border-0">
                <span className="fw-normal d-flex align-items-center"
                ><svg
                  className="icon icon-xxs text-danger me-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Email</span
                >
              </td>
              <td className="border-0">
                <span className="fw-normal text-danger">Suspended</span>
              </td>
              <td className="border-0">
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div
                    className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                  >
                    <Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Reset Pass </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-gray-400 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path
                          fillRule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      View Details </Link
                    ><Link className="dropdown-item d-flex align-items-center" href="#"
                    ><svg
                      className="dropdown-icon text-danger me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"
                        ></path>
                      </svg>
                      Suspend</Link
                    >
                  </div>
                </div>
                <svg
                  className="icon icon-xs text-danger ms-1"
                  title="Delete"
                  data-bs-toggle="tooltip"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <nav aria-label="Page navigation example">
            <ul className="pagination mb-0">
              <li className="page-item">
                <Link className="page-link" href="#">Previous</Link>
              </li>
              <li className="page-item"><Link className="page-link" href="#">1</Link></li>
              <li className="page-item active">
                <Link className="page-link" href="#">2</Link>
              </li>
              <li className="page-item"><Link className="page-link" href="#">3</Link></li>
              <li className="page-item"><Link className="page-link" href="#">4</Link></li>
              <li className="page-item"><Link className="page-link" href="#">5</Link></li>
              <li className="page-item"><Link className="page-link" href="#">Next</Link></li>
            </ul>
          </nav>
          <div className="fw-normal small mt-4 mt-lg-0">
            Showing <b>5</b> out of <b>25</b> entries
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Transactions" description="List of components" link="/" linkName="Detail" />

      <div className="table-settings mb-4">
        <div className="row align-items-center justify-content-between">
          <div className="col col-md-6 col-lg-3 col-xl-4">
            <div className="input-group me-2 me-lg-3 fmxw-400">
              <span className="input-group-text"
              ><svg
                className="icon icon-xs"
                x-description="Heroicon name: solid/search"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg> </span
              ><input
                type="text"
                className="form-control"
                placeholder="Search orders"
              />
            </div>
          </div>
          <div className="col-4 col-md-2 col-xl-1 ps-md-0 text-end">
            <div className="dropdown">
              <button
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-1"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg
                  className="icon icon-sm"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-xs dropdown-menu-end pb-0"
              >
                <span className="small ps-3 fw-bold text-dark">Show</span>
                <Link
                  className="dropdown-item d-flex align-items-center fw-bold"
                  href="#"
                >10
                  <svg
                    className="icon icon-xxs ms-auto"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path></svg></Link
                ><Link className="dropdown-item fw-bold" href="#">20</Link>
                <Link className="dropdown-item fw-bold rounded-bottom" href="#">30</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card card-body border-0 shadow table-wrapper table-responsive"
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="border-gray-200">#</th>
              <th className="border-gray-200">Bill For</th>
              <th className="border-gray-200">Issue Date</th>
              <th className="border-gray-200">Due Date</th>
              <th className="border-gray-200">Total</th>
              <th className="border-gray-200">Status</th>
              <th className="border-gray-200">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456478</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 May 2020</span></td>
              <td><span className="fw-normal">1 Jun 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-warning">Due</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456423</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Apr 2020</span></td>
              <td><span className="fw-normal">1 May 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456420</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Mar 2020</span></td>
              <td><span className="fw-normal">1 Apr 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456421</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Feb 2020</span></td>
              <td><span className="fw-normal">1 Mar 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456420</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Jan 2020</span></td>
              <td><span className="fw-normal">1 Feb 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456479</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Dec 2019</span></td>
              <td><span className="fw-normal">1 Jan 2020</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456478</Link></td>
              <td><span className="fw-normal">Platinum Subscription Plan</span></td>
              <td><span className="fw-normal">1 Nov 2019</span></td>
              <td><span className="fw-normal">1 Dec 2019</span></td>
              <td><span className="fw-bold">$799,00</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">453673</Link></td>
              <td><span className="fw-normal">Gold Subscription Plan</span></td>
              <td><span className="fw-normal">1 Oct 2019</span></td>
              <td><span className="fw-normal">1 Nov 2019</span></td>
              <td><span className="fw-bold">$533,42</span></td>
              <td><span className="fw-bold text-danger">Cancelled</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456468</Link></td>
              <td><span className="fw-normal">Gold Subscription Plan</span></td>
              <td><span className="fw-normal">1 Sep 2019</span></td>
              <td><span className="fw-normal">1 Oct 2019</span></td>
              <td><span className="fw-bold">$533,42</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td><Link href="../invoice.html" className="fw-bold">456478</Link></td>
              <td><span className="fw-normal">Flexible Subscription Plan</span></td>
              <td><span className="fw-normal">1 Aug 2019</span></td>
              <td><span className="fw-normal">1 Sep 2019</span></td>
              <td><span className="fw-bold">$233,42</span></td>
              <td><span className="fw-bold text-success">Paid</span></td>
              <td>
                <div className="btn-group">
                  <button
                    className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="icon icon-sm"
                    ><span className="fas fa-ellipsis-h icon-dark"></span> </span
                    ><span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div className="dropdown-menu py-0">
                    <Link className="dropdown-item rounded-top" href="../invoice.html"
                    ><span className="fas fa-eye me-2"></span>View Details</Link
                    >
                    <Link className="dropdown-item" href="#"
                    ><span className="fas fa-edit me-2"></span>Edit</Link
                    >
                    <Link className="dropdown-item text-danger rounded-bottom" href="#"
                    ><span className="fas fa-trash-alt me-2"></span>Remove</Link
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between"
        >
          <nav aria-label="Page navigation example">
            <ul className="pagination mb-0">
              <li className="page-item">
                <Link className="page-link" href="#">Previous</Link>
              </li>
              <li className="page-item"><Link className="page-link" href="#">1</Link></li>
              <li className="page-item active">
                <Link className="page-link" href="#">2</Link>
              </li>
              <li className="page-item"><Link className="page-link" href="#">3</Link></li>
              <li className="page-item"><Link className="page-link" href="#">4</Link></li>
              <li className="page-item"><Link className="page-link" href="#">5</Link></li>
              <li className="page-item"><Link className="page-link" href="#">Next</Link></li>
            </ul>
          </nav>
          <div className="fw-normal small mt-4 mt-lg-0">
            Showing <b>5</b> out of <b>25</b> entries
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Task" description="List of components" link="/" linkName="Detail" />
      <div
        className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
      >
        <div className="col-auto d-flex justify-content-between ps-0 mb-4 mb-lg-0">
          <div className="me-lg-3">
            <div className="dropdown">
              <button
                className="btn btn-secondary d-inline-flex align-items-center me-2 dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  ></path>
                </svg>
                New Task
              </button>
              <div
                className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
              >
                <Link className="dropdown-item d-flex align-items-center" href="#"
                ><svg
                  className="dropdown-icon text-gray-400 me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                    ></path>
                  </svg>
                  Add User </Link
                ><Link className="dropdown-item d-flex align-items-center" href="#"
                ><svg
                  className="dropdown-icon text-gray-400 me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                    ></path>
                  </svg>
                  Add Widget </Link
                ><Link className="dropdown-item d-flex align-items-center" href="#"
                ><svg
                  className="dropdown-icon text-gray-400 me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                    ></path>
                    <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                  </svg>
                  Upload Files </Link
                ><Link className="dropdown-item d-flex align-items-center" href="#"
                ><svg
                  className="dropdown-icon text-gray-400 me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Preview Security</Link
                >
                <div role="separator" className="dropdown-divider my-1"></div>
                <Link className="dropdown-item d-flex align-items-center" href="#"
                ><svg
                  className="dropdown-icon text-danger me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      fillRule="evenodd"
                      d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Upgrade to Pro</Link
                >
              </div>
            </div>
          </div>
          <div className="btn-group">
            <button className="btn btn-gray-800">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                <path
                  fillRule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <button className="btn btn-gray-800 text-white">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <button className="btn btn-gray-800 text-white">
              <svg
                className="icon icon-xs text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex justify-content-lg-end">
          <input
            type="text"
            className="form-control w-100 fmxw-300"
            id="exampleInputIconLeft"
            placeholder="Search Tasks Here"
            aria-label="Search"
            aria-describedby="basic-addon3"
          />
        </div>
      </div>
      <div className="task-wrapper border bg-white shadow border-0 rounded">
        <div className="card hover-state border-bottom rounded-0 rounded-top py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck1"
                  checked="checked"
                />
                <label className="form-check-label" for="mailCheck1"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5 line-through">
                  Meeting with Ms.Bonnie from Themesberg LLC
                </h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4 className="h6 fw-normal text-gray mb-3 mb-sm-0">
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-success">Done</span>
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray line-through"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck11"
                />
                <label className="form-check-label" for="mailCheck11"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-warning"
                    >In Progress</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck2"
                />
                <label className="form-check-label" for="mailCheck2"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-warning"
                    >In Progress</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck3"
                />
                <label className="form-check-label" for="mailCheck3"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-warning"
                    >In Progress</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck4"
                />
                <label className="form-check-label" for="mailCheck4"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-purple">Waiting</span>
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck5"
                />
                <label className="form-check-label" for="mailCheck5"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-purple">Waiting</span>
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card hover-state border-bottom rounded-0 py-3">
          <div
            className="card-body d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0"
          >
            <div className="col-1 text-left text-sm-center mb-2 mb-sm-0">
              <div className="form-check check-lg inbox-check me-sm-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mailCheck6"
                />
                <label className="form-check-label" for="mailCheck6"></label>
              </div>
            </div>
            <div className="col-11 col-lg-8 px-0 mb-4 mb-md-0">
              <div className="mb-2">
                <h3 className="h5">Meeting with Ms.Bonnie from Themesberg LLC</h3>
                <div className="d-block d-sm-flex">
                  <div>
                    <h4
                      className="h6 fw-normal text-gray d-flex align-items-center mb-3 mb-sm-0"
                    >
                      <svg
                        className="icon icon-xxs text-gray-500 me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      10:00 AM
                    </h4>
                  </div>
                  <div className="ms-sm-3">
                    <span className="badge super-badge bg-purple">Waiting</span>
                  </div>
                </div>
              </div>
              <div>
                <Link href="single-message.html" className="fw-bold text-dark"
                ><span className="fw-normal text-gray"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis
                    id varius vel, aliquet non augue.</span
                  ></Link
                >
              </div>
            </div>
            <div
              className="col-10 col-sm-2 col-lg-2 col-xl-2 d-none d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-right justify-content-end px-md-0"
            >
              <div className="dropdown">
                <button
                  className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    className="icon icon-xs"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
                    ></path>
                  </svg>
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <div
                  className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
                >
                  <Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Edit </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-gray-400 me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                      ></path>
                    </svg>
                    Mark as Important </Link
                  ><Link className="dropdown-item d-flex align-items-center" href="#"
                  ><svg
                    className="dropdown-icon text-danger me-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Delete</Link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-4">
          <div className="col-7 mt-1">Showing 1 - 20 of 289</div>
          <div className="col-5">
            <div className="btn-group float-end">
              <Link href="#" className="btn btn-gray-100"
              ><svg
                className="icon icon-sm"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg> </Link
              ><Link href="#" className="btn btn-gray-800"
              ><svg
                className="icon icon-sm"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path></svg
                ></Link>
            </div>
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Settings" description="List of components" link="/" linkName="Detail" />
      <div
        className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
      >
        <div>
          <div className="dropdown">
            <button
              className="btn btn-secondary d-inline-flex align-items-center me-2 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg
                className="icon icon-xs me-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              New
            </button>
            <div
              className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
            >
              <Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Document </Link
              ><Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Message </Link
              ><Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-gray-400 me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"
                  ></path>
                  <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                </svg>
                Product</Link
              >
              <div role="separator" className="dropdown-divider my-1"></div>
              <Link className="dropdown-item d-flex align-items-center" href="#"
              ><svg
                className="dropdown-icon text-danger me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                    fillRule="evenodd"
                    d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                My Plan</Link
              >
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-gray-800 d-inline-flex align-items-center me-2"
          >
            <svg
              className="icon icon-xs"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <button
            className="btn btn-gray-800 d-inline-flex align-items-center dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg
              className="icon icon-xs me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clipRule="evenodd"
              ></path>
            </svg>
            Reports
            <svg
              className="icon icon-xs ms-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1"
          >
            <Link className="dropdown-item d-flex align-items-center" href="#"
            ><svg
              className="dropdown-icon text-gray-400 me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                <path
                  fillRule="evenodd"
                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Products </Link
            ><Link className="dropdown-item d-flex align-items-center" href="#"
            ><svg
              className="dropdown-icon text-gray-400 me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                ></path>
              </svg>
              Customers </Link
            ><Link className="dropdown-item d-flex align-items-center" href="#"
            ><svg
              className="dropdown-icon text-gray-400 me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  fillRule="evenodd"
                  d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Orders </Link
            ><Link className="dropdown-item d-flex align-items-center" href="#"
            ><svg
              className="dropdown-icon text-gray-400 me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  fillRule="evenodd"
                  d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Console</Link
            >
            <div role="separator" className="dropdown-divider my-1"></div>
            <Link className="dropdown-item d-flex align-items-center" href="#"
            ><svg
              className="dropdown-icon text-gray-800 me-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clipRule="evenodd"
                ></path>
              </svg>
              All Reports</Link
            >
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-8">
          <div className="card card-body border-0 shadow mb-4">
            <h2 className="h5 mb-4">Component information</h2>
            <form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label for="first_name">First Name</label>
                    <input
                      className="form-control"
                      id="first_name"
                      type="text"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label for="last_name">Last Name</label>
                    <input
                      className="form-control"
                      id="last_name"
                      type="text"
                      placeholder="Also your last name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 mb-3">
                  <label for="birthday">Birthday</label>
                  <div className="input-group">
                    <span className="input-group-text"
                    ><svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                          fillRule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clipRule="evenodd"
                        ></path>
                      </svg> </span
                    ><input
                      data-datepicker=""
                      className="form-control"
                      id="birthday"
                      type="text"
                      placeholder="dd/mm/yyyy"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label for="gender">Gender</label>
                  <select
                    className="form-select mb-0"
                    id="gender"
                    aria-label="Gender select example"
                  >
                    <option selected="selected">Gender</option>
                    <option value="1">Female</option>
                    <option value="2">Male</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label for="email">Email</label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label for="phone">Phone</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="number"
                      placeholder="+12-345 678 910"
                      required
                    />
                  </div>
                </div>
              </div>
              <h2 className="h5 my-4">Location</h2>
              <div className="row">
                <div className="col-sm-9 mb-3">
                  <div className="form-group">
                    <label for="address">Address</label>
                    <input
                      className="form-control"
                      id="address"
                      type="text"
                      placeholder="Enter your home address"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-3 mb-3">
                  <div className="form-group">
                    <label for="number">Number</label>
                    <input
                      className="form-control"
                      id="number"
                      type="number"
                      placeholder="No."
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 mb-3">
                  <div className="form-group">
                    <label for="city">City</label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-4 mb-3">
                  <label for="state">State</label>
                  <select
                    className="form-select w-100 mb-0"
                    id="state"
                    name="state"
                    aria-label="State select example"
                  >
                    <option selected="selected">State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label for="zip">ZIP</label>
                    <input
                      className="form-control"
                      id="zip"
                      type="tel"
                      placeholder="ZIP"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-gray-800 mt-2 animate-up-2"
                  type="submit"
                >
                  Save all
                </button>
              </div>
            </form>
          </div>
          <div className="card card-body border-0 shadow mb-4 mb-xl-0">
            <h2 className="h5 mb-4">Alerts & Notifications</h2>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item d-flex align-items-center justify-content-between px-0 border-bottom"
              >
                <div>
                  <h3 className="h6 mb-1">Company News</h3>
                  <p className="small pe-4">
                    Get Rocket news, announcements, and product updates
                  </p>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="user-notification-1"
                    />
                    <label
                      className="form-check-label"
                      for="user-notification-1"
                    ></label>
                  </div>
                </div>
              </li>
              <li
                className="list-group-item d-flex align-items-center justify-content-between px-0 border-bottom"
              >
                <div>
                  <h3 className="h6 mb-1">Account Activity</h3>
                  <p className="small pe-4">
                    Get important notifications about you or activity you've
                    missed
                  </p>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="user-notification-2"
                      checked="checked"
                    />
                    <label
                      className="form-check-label"
                      for="user-notification-2"
                    ></label>
                  </div>
                </div>
              </li>
              <li
                className="list-group-item d-flex align-items-center justify-content-between px-0"
              >
                <div>
                  <h3 className="h6 mb-1">Meetups Near You</h3>
                  <p className="small pe-4">
                    Get an email when a Dribbble Meetup is posted close to my
                    location
                  </p>
                </div>
                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="user-notification-3"
                      checked="checked"
                    />
                    <label
                      className="form-check-label"
                      for="user-notification-3"
                    ></label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="card shadow border-0 text-center p-0">
                <div
                  className="profile-cover rounded-top"
                  data-background="../assets/img/profile-cover.jpg"
                ></div>
                <div className="card-body pb-5">
                  <img
                    src="../assets/img/team/profile-picture-1.jpg"
                    className="avatar-xl rounded-circle mx-auto mt-n7 mb-4"
                    alt="Neil Portrait"
                  />
                  <h4 className="h3">Neil Sims</h4>
                  <h5 className="fw-normal">Senior Software Engineer</h5>
                  <p className="text-gray mb-4">New York, USA</p>
                  <Link
                    className="btn btn-sm btn-gray-800 d-inline-flex align-items-center me-2"
                    href="#"
                  ><svg
                    className="icon icon-xs me-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                        d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                      ></path>
                    </svg>
                    Connect </Link
                  ><Link className="btn btn-sm btn-secondary" href="#">Send Message</Link>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card card-body border-0 shadow mb-4">
                <h2 className="h5 mb-4">Select profile photo</h2>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      className="rounded avatar-xl"
                      src="../assets/img/team/profile-picture-3.jpg"
                      alt="change avatar"
                    />
                  </div>
                  <div className="file-field">
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                      <div className="d-flex">
                        <svg
                          className="icon text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <input type="file" />
                        <div className="d-md-block text-left">
                          <div className="fw-normal text-dark mb-1">
                            Choose Image
                          </div>
                          <div className="text-gray small">
                            JPG, GIF or PNG. Max size of 800K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-12">
              <form
                action="#"
                className="dropzone rounded mb-4"
              >
                <div className="fallback">
                  <input name="file" type="file" multiple="multiple" />
                </div>
              </form>
            </div>
            <div className="col-12 col-sm-6 col-xl-12">
              <div className="card card-body border-0 shadow mb-4">
                <h2 className="h5 mb-4">Select cover photo</h2>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      className="rounded avatar-xl"
                      src="../assets/img/profile-cover.jpg"
                      alt="change cover"
                    />
                  </div>
                  <div className="file-field">
                    <div className="d-flex justify-content-xl-center ms-xl-3">
                      <div className="d-flex">
                        <svg
                          className="icon text-gray-500 me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <input type="file" />
                        <div className="d-md-block text-left">
                          <div className="fw-normal text-dark mb-1">
                            Choose Image
                          </div>
                          <div className="text-gray small">
                            JPG, GIF or PNG. Max size of 800K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Calender" description="List of components" link="/" linkName="Detail" />

      <div className="card border-0 shadow">
        <div id="calendar" className="p-4"></div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Data Table" description="List of components" link="/" linkName="Detail" />
      <div className="card">
        <div className="table-responsive py-4">
          <table className="table table-flush" id="datatable">
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Office</th>
                <th>Age</th>
                <th>Start date</th>
                <th>Salary</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tiger Nixon</td>
                <td>System Architect</td>
                <td>Edinburgh</td>
                <td>61</td>
                <td>2011/04/25</td>
                <td>$320,800</td>
              </tr>
              <tr>
                <td>Garrett Winters</td>
                <td>Accountant</td>
                <td>Tokyo</td>
                <td>63</td>
                <td>2011/07/25</td>
                <td>$170,750</td>
              </tr>
              <tr>
                <td>Ashton Cox</td>
                <td>Junior Technical Author</td>
                <td>San Francisco</td>
                <td>66</td>
                <td>2009/01/12</td>
                <td>$86,000</td>
              </tr>
              <tr>
                <td>Cedric Kelly</td>
                <td>Senior Javascript Developer</td>
                <td>Edinburgh</td>
                <td>22</td>
                <td>2012/03/29</td>
                <td>$433,060</td>
              </tr>

              <tr>
                <td>Donna Snider</td>
                <td>Customer Support</td>
                <td>New York</td>
                <td>27</td>
                <td>2011/01/25</td>
                <td>$112,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Bootstrap Table" description="List of components" link="/" linkName="Detail" />
      <div className="card border-0 shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0 rounded">
              <thead className="thead-light">
                <tr>
                  <th className="border-0 rounded-start">#</th>
                  <th className="border-0">Traffic Source</th>
                  <th className="border-0">Source Type</th>
                  <th className="border-0">Category</th>
                  <th className="border-0">Global Rank</th>
                  <th className="border-0">Traffic Share</th>
                  <th className="border-0 rounded-end">Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Link href="#" className="text-primary fw-bold">1</Link></td>
                  <td className="fw-bold d-flex align-items-center">
                    <svg
                      className="icon icon-xxs text-gray-500 me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Direct
                  </td>
                  <td>Direct</td>
                  <td>-</td>
                  <td>--</td>
                  <td>
                    <div className="row d-flex align-items-center">
                      <div className="col-12 col-xl-2 px-0">
                        <div className="small fw-bold">51%</div>
                      </div>
                      <div className="col-12 col-xl-10 px-0 px-xl-1">
                        <div className="progress progress-lg mb-0">
                          <div
                            className="progress-bar bg-dark"
                            role="progressbar"
                            aria-valuenow="51"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">2.45%</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><Link href="#" className="text-primary fw-bold">2</Link></td>
                  <td className="fw-bold d-flex align-items-center">
                    <svg
                      className="icon icon-xxs text-gray-500 me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="google"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488 512"
                    >
                      <path
                        fill="currentColor"
                        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                      ></path>
                    </svg>
                    Google Search
                  </td>
                  <td>Search / Organic</td>
                  <td>-</td>
                  <td>--</td>
                  <td>
                    <div className="row d-flex align-items-center">
                      <div className="col-12 col-xl-2 px-0">
                        <div className="small fw-bold">18%</div>
                      </div>
                      <div className="col-12 col-xl-10 px-0 px-xl-1">
                        <div className="progress progress-lg mb-0">
                          <div
                            className="progress-bar bg-dark"
                            role="progressbar"
                            aria-valuenow="18"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">17.78%</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><Link href="#" className="text-primary fw-bold">3</Link></td>
                  <td className="fw-bold d-flex align-items-center">
                    <svg
                      className="icon icon-xxs text-gray-500 me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="youtube"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                      ></path>
                    </svg>
                    youtube.com
                  </td>
                  <td>Social</td>
                  <td>
                    <Link className="small fw-bold" href="#">Arts and Entertainment</Link>
                  </td>
                  <td>#2</td>
                  <td>
                    <div className="row d-flex align-items-center">
                      <div className="col-12 col-xl-2 px-0">
                        <div className="small fw-bold">18%</div>
                      </div>
                      <div className="col-12 col-xl-10 px-0 px-xl-1">
                        <div className="progress progress-lg mb-0">
                          <div
                            className="progress-bar bg-dark"
                            role="progressbar"
                            aria-valuenow="18"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><Link href="#" className="text-primary fw-bold">4</Link></td>
                  <td className="fw-bold d-flex align-items-center">
                    <svg
                      className="icon icon-xxs text-gray-500 me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="yahoo"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M223.69,141.06,167,284.23,111,141.06H14.93L120.76,390.19,82.19,480h94.17L317.27,141.06Zm105.4,135.79a58.22,58.22,0,1,0,58.22,58.22A58.22,58.22,0,0,0,329.09,276.85ZM394.65,32l-93,223.47H406.44L499.07,32Z"
                      ></path>
                    </svg>
                    yahoo.com
                  </td>
                  <td>Referral</td>
                  <td><Link className="small fw-bold" href="#">News and Media</Link></td>
                  <td>#11</td>
                  <td>
                    <div className="row d-flex align-items-center">
                      <div className="col-12 col-xl-2 px-0">
                        <div className="small fw-bold">8%</div>
                      </div>
                      <div className="col-12 col-xl-10 px-0 px-xl-1">
                        <div className="progress progress-lg mb-0">
                          <div
                            className="progress-bar bg-dark"
                            role="progressbar"
                            aria-valuenow="8"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "75%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">9.45%</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><Link href="#" className="text-primary fw-bold">5</Link></td>
                  <td className="fw-bold d-flex align-items-center">
                    <svg
                      className="icon icon-xxs text-gray-500 me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="twitter"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      ></path>
                    </svg>
                    twitter.com
                  </td>
                  <td>Social</td>
                  <td><Link className="small fw-bold" href="#">Social Networks</Link></td>
                  <td>#4</td>
                  <td>
                    <div className="row d-flex align-items-center">
                      <div className="col-12 col-xl-2 px-0">
                        <div className="small fw-bold">4%</div>
                      </div>
                      <div className="col-12 col-xl-10 px-0 px-xl-1">
                        <div className="progress progress-lg mb-0">
                          <div
                            className="progress-bar bg-dark"
                            role="progressbar"
                            aria-valuenow="4"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "4%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card border-0 shadow">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0 rounded">
              <thead className="thead-light">
                <tr>
                  <th className="border-0 rounded-start">Country</th>
                  <th className="border-0">All</th>
                  <th className="border-0">All Change</th>
                  <th className="border-0">Travel & Local</th>
                  <th className="border-0">Travel & Local Change</th>
                  <th className="border-0">Widgets</th>
                  <th className="border-0 rounded-end">Widgets Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/united-states-of-america.svg"
                      />
                      <div><span className="h6">United States</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">106</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">5</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">3</td>
                  <td className="border-0">=</td>
                  <td className="border-0 fw-bold">32</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">3</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/canada.svg"
                      />
                      <div><span className="h6">Canada</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">76</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">17</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">4</td>
                  <td className="border-0">=</td>
                  <td className="border-0 fw-bold">30</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">3</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/united-kingdom.svg"
                      />
                      <div><span className="h6">United Kingdom</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">147</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">10</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">5</td>
                  <td className="border-0">=</td>
                  <td className="border-0 fw-bold">34</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">7</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/france.svg"
                      />
                      <div><span className="h6">France</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">112</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">3</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">5</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">1</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">34</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">2</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/japan.svg"
                      />
                      <div><span className="h6">Japan</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">115</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">12</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">6</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">1</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">37</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">5</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border-0">
                    <Link href="#" className="d-flex align-items-center"
                    ><img
                        className="me-2 image image-small rounded-circle"
                        alt="placeholder"
                        src="../../assets/img/flags/germany.svg"
                      />
                      <div><span className="h6">Germany</span></div></Link
                    >
                  </td>
                  <td className="border-0 fw-bold">220</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">56</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">7</td>
                  <td className="border-0 text-danger">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">3</span>
                    </div>
                  </td>
                  <td className="border-0 fw-bold">30</td>
                  <td className="border-0 text-success">
                    <div className="d-flex align-items-center">
                      <svg
                        className="icon icon-xs me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="fw-bold">2</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="Components" description="List of components" link="/" linkName="Detail" />
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card border-light shadow-sm components-section">
            <div className="card-body">
              <div className="mb-3"><h2 className="h5">Sizes</h2></div>
              <button className="btn btn-sm btn-primary" type="button">
                Small
              </button>
              <button className="btn btn-primary" type="button">Regular</button>
              <button className="btn btn-lg btn-primary" type="button">
                Large Button
              </button>
              <h2 className="h5 fw-bold mt-4 mb-3">With Icons</h2>
              <button
                type="button"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                Download
                <svg
                  className="icon icon-xxs ms-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="btn btn-primary d-inline-flex align-items-center"
              >
                <svg
                  className="icon icon-xxs me-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  ></path>
                </svg>
                Contact Us
              </button>
              <h2 className="h5 fw-bold mt-4 mb-3">Dropdown buttons</h2>
              <div className="d-flex">
                <div className="dropdown me-1">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    id="dropdownMenuOffset"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-offset="10,20"
                  >
                    Offset
                  </button>
                  <ul
                    className="dropdown-menu py-0"
                    aria-labelledby="dropdownMenuOffset"
                  >
                    <li>
                      <Link className="dropdown-item rounded-top" href="#">Action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">Another action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item rounded-bottom" href="#"
                      >Something else here</Link
                      >
                    </li>
                  </ul>
                </div>
                <div className="btn-group">
                  <button type="button" className="btn btn-secondary">
                    Reference
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                    id="dropdownMenuReference"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-reference="parent"
                  >
                    <svg
                      className="icon icon-xs"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul
                    className="dropdown-menu py-0"
                    aria-labelledby="dropdownMenuReference"
                  >
                    <li>
                      <Link className="dropdown-item rounded-top" href="#">Action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">Another action</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" href="#">Something else here</Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <Link className="dropdown-item rounded-bottom" href="#"
                      >Separated link</Link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-3 mt-5"><h2 className="h5">Link Buttons</h2></div>
              <Link href="#" className="text-default fw-bold me-3">Primary</Link>
              <Link
                href="#"
                className="text-primary d-inline-flex align-items-center me-3"
              ><svg
                className="icon icon-xxs me-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                  <path
                    fillRule="evenodd"
                    d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                    clipRule="evenodd"
                  />
                </svg>
                Icon Left </Link
              ><Link href="#" className="text-primary d-inline-flex align-items-center"
              >Icon Right
                <svg
                  className="icon icon-xxs ms-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
              <div className="mb-3 mt-5"><h2 className="h5">Tooltips</h2></div>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Tooltip on top"
              >
                Tooltip on top
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Tooltip on right"
              >
                Tooltip on right
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Tooltip on bottom"
              >
                Tooltip on bottom
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Tooltip on left"
              >
                Tooltip on left
              </button>
              <div className="mb-3 mt-5"><h2 className="h5">Popovers</h2></div>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="top"
                data-bs-content="Top popover"
              >
                Popover on top
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="right"
                data-bs-content="Right popover"
              >
                Popover on right
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="bottom"
                data-bs-content="Bottom popover"
              >
                Popover on bottom
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="left"
                data-bs-content="Left popover"
              >
                Popover on left
              </button>
              <div className="mb-3 mt-5"><h2 className="h5">Choose your color</h2></div>
              <div className="mb-3 mt-5">
                <small className="text-uppercase fw-bold">Main</small>
              </div>
              <button className="btn btn-primary" type="button">Primary</button>
              <button className="btn btn-secondary" type="button">Secondary</button>
              <button className="btn btn-tertiary" type="button">Tertiary</button>
              <button className="btn btn-info" type="button">Info</button>
              <button className="btn btn-success" type="button">Success</button>
              <button className="btn btn-warning" type="button">Warning</button>
              <button className="btn btn-danger" type="button">Danger</button>
              <button className="btn btn-gray-800" type="button">Dark</button>
              <button className="btn btn-gray-200" type="button">Gray</button>
              <button className="btn btn-gray-50" type="button">Light</button>
              <button className="btn btn-white" type="button">White</button>
              <div className="mb-3 mt-5">
                <small className="text-uppercase fw-bold">Outline</small>
              </div>
              <button className="btn btn-outline-primary" type="button">
                Primary
              </button>
              <button className="btn btn-outline-secondary" type="button">
                Secondary
              </button>
              <button className="btn btn-outline-tertiary" type="button">
                Tertiary
              </button>
              <button className="btn btn-outline-info" type="button">Info</button>
              <button className="btn btn-outline-success" type="button">
                Success
              </button>
              <button className="btn btn-outline-danger" type="button">
                Danger
              </button>
              <button className="btn btn-outline-gray-800" type="button">
                Dark
              </button>
              <button className="btn btn-outline-gray-500" type="button">
                Gray
              </button>
              <div className="mb-3 mt-5">
                <small className="text-uppercase fw-bold">Round Outline</small>
              </div>
              <button className="btn btn-pill btn-outline-primary" type="button">
                Primary
              </button>
              <button className="btn btn-pill btn-outline-secondary" type="button">
                Secondary
              </button>
              <button className="btn btn-pill btn-outline-tertiary" type="button">
                Tertiary
              </button>
              <button className="btn btn-pill btn-outline-info" type="button">
                Info
              </button>
              <button className="btn btn-pill btn-outline-success" type="button">
                Success
              </button>
              <button className="btn btn-pill btn-outline-danger" type="button">
                Danger
              </button>
              <button className="btn btn-pill btn-outline-gray-800" type="button">
                Dark
              </button>
              <button className="btn btn-pill btn-outline-gray-500" type="button">
                Gray
              </button>
              <div className="mb-3 mt-5">
                <small className="text-uppercase fw-bold">Links</small>
              </div>
              <Link href="#" className="text-default me-3">Default</Link>
              <Link href="#" className="text-primary me-3">Primary</Link>
              <Link href="#" className="text-secondary me-3">Secondary</Link>
              <Link href="#" className="text-tertiary me-3">Tertiary</Link>
              <Link href="#" className="text-info me-3">Info</Link>
              <Link href="#" className="text-success me-3">Success</Link>
              <Link href="#" className="text-danger me-3">Danger</Link>
              <Link href="#" className="text-dark me-3">Dark</Link>
              <Link href="#" className="text-gray">Gray</Link>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="mb-4 mt-5">
                    <h2 className="h5 fw-bold">Social Buttons</h2>
                  </div>
                  <button
                    className="btn btn-facebook d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="facebook-f"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                      ></path>
                    </svg>
                    Login with Facebook</button
                  ><br /><button
                    className="btn btn-twitter text-white d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="twitter"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      ></path>
                    </svg>
                    Share on Twitter</button
                  ><br /><button
                    className="btn btn-youtube d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="youtube"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                      ></path>
                    </svg>
                    Watch on YouTube</button
                  ><br /><button
                    className="btn btn-github d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="github"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path
                        fill="currentColor"
                        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                      ></path>
                    </svg>
                    Login with GitHub</button
                  ><br /><button
                    className="btn btn-paypal d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="paypal"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
                      ></path>
                    </svg>
                    Donate with PayPal</button
                  ><br /><button
                    className="btn btn-behance d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs me-2"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="behance"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"
                      ></path>
                    </svg>
                    Follow us
                  </button>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-4 mt-5"><h2 className="h5">Only Icon</h2></div>
                  <button
                    className="btn btn-icon-only btn-facebook d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="facebook-f"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                      ></path>
                    </svg></button
                  ><br /><button
                    className="btn btn-icon-only btn-twitter text-white d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="twitter"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      ></path>
                    </svg></button
                  ><br /><button
                    className="btn btn-icon-only btn-youtube d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="youtube"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                      ></path>
                    </svg></button
                  ><br /><button
                    className="btn btn-icon-only btn-github d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="github"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path
                        fill="currentColor"
                        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                      ></path>
                    </svg></button
                  ><br /><button
                    className="btn btn-icon-only btn-paypal d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="paypal"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
                      ></path>
                    </svg></button
                  ><br /><button
                    className="btn btn-icon-only btn-behance d-inline-flex align-items-center"
                    type="button"
                  >
                    <svg
                      className="icon icon-xxs"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="behance"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M232 237.2c31.8-15.2 48.4-38.2 48.4-74 0-70.6-52.6-87.8-113.3-87.8H0v354.4h171.8c64.4 0 124.9-30.9 124.9-102.9 0-44.5-21.1-77.4-64.7-89.7zM77.9 135.9H151c28.1 0 53.4 7.9 53.4 40.5 0 30.1-19.7 42.2-47.5 42.2h-79v-82.7zm83.3 233.7H77.9V272h84.9c34.3 0 56 14.3 56 50.6 0 35.8-25.9 47-57.6 47zm358.5-240.7H376V94h143.7v34.9zM576 305.2c0-75.9-44.4-139.2-124.9-139.2-78.2 0-131.3 58.8-131.3 135.8 0 79.9 50.3 134.7 131.3 134.7 61.3 0 101-27.6 120.1-86.3H509c-6.7 21.9-34.3 33.5-55.7 33.5-41.3 0-63-24.2-63-65.3h185.1c.3-4.2.6-8.7.6-13.2zM390.4 274c2.3-33.7 24.7-54.8 58.5-54.8 35.4 0 53.2 20.8 56.2 54.8H390.4z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card border-0 shadow">
            <div
              className="card-header border-gray-100 d-flex justify-content-between align-items-center">
              <h2 className="h4 mb-0">Sweet alerts</h2>
              <Link
                to="#"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center"
              ><svg
                className="icon icon-xxs me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Documentation</Link>
            </div>
            <div className="card-body">
              <button className="btn btn-gray-800" id="basicAlert">
                Basic alert
              </button>
              <button className="btn btn-info" id="infoAlert">Info alert</button>
              <button className="btn btn-success" id="successAlert">
                Success alert
              </button>
              <button className="btn btn-danger" id="dangerAlert">
                Danger alert
              </button>
              <button className="btn btn-warning" id="warningAlert">
                Warning alert
              </button>
              <button className="btn btn-gray-200" id="questionAlert">
                Question
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card border-0 shadow">
            <div
              className="card-header border-gray-100 d-flex justify-content-between align-items-center"
            >
              <h2 className="h4 mb-0">Notyf</h2>
              <Link
                to="#"
                className="btn btn-outline-gray-500 d-inline-flex align-items-center"
              ><svg
                className="icon icon-xxs me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Documentation</Link>
            </div>
            <div className="card-body">
              <button className="btn btn-info" id="notifyTopLeft">
                Top left info
              </button>
              <button className="btn btn-danger" id="notifyTopRight">
                Top right danger
              </button>
              <button className="btn btn-warning" id="notifyBottomLeft">
                Bottom left warning
              </button>
              <button className="btn btn-gray-800" id="notifyBottomRight">
                Primary bottom right
              </button>
            </div>
          </div>
        </div>
      </div>



      <div className="row">
        <div className="col-12 mb-4">
          <div className="card border-0 shadow components-section">
            <div className="card-body">
              <div className="row mb-4">
                <div className="col-lg-4 col-sm-6">
                  <div className="mb-4">
                    <label for="email">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                    <small id="emailHelp" className="form-text text-muted"
                    >We'll never share your email with anyone else.</small
                    >
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputIconLeft">Icon Left</label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1"
                      ><svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg> </span
                      ><input
                        type="text"
                        className="form-control"
                        id="exampleInputIconLeft"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputIconRight">Icon Right</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputIconRight"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <span className="input-group-text" id="basic-addon2"
                      ><svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path></svg
                        ></span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="exampleInputIconPassword">Password</label>
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputIconPassword"
                        placeholder="Password"
                        aria-label="Password"
                      />
                      <span className="input-group-text" id="basic-addon3"
                      ><svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          ></path></svg
                        ></span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="firstName">First name</label>
                    <input
                      type="text"
                      className="form-control is-valid"
                      id="firstName"
                      value="Mark"
                      required
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="mb-2">
                    <label className="my-1 me-2" for="state">Select state:</label>
                    <select id="state" className="w-100" name="state">
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                  <label className="my-1 me-2" for="states">Select states:</label>
                  <select
                    id="states"
                    className="w-100"
                    name="states[]"
                    multiple="multiple"
                  >
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <div className="my-4">
                    <label for="textarea">Example textarea</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter your message..."
                      id="textarea"
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label for="usernameValidate">Username</label>
                    <input
                      type="text"
                      className="form-control is-invalid"
                      id="usernameValidate"
                      required
                    />
                    <div className="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="mb-3">
                    <label for="birthday">Birthday</label>
                    <div className="input-group">
                      <span className="input-group-text"
                      ><svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                          ></path>
                        </svg> </span
                      ><input
                        data-datepicker=""
                        className="form-control"
                        id="birthday"
                        type="text"
                        placeholder="dd/mm/yyyy"
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="disabledTextInput">Name</label>
                    <input
                      type="text"
                      id="disabledTextInput"
                      className="form-control"
                      placeholder="Disabled input"
                      disabled="disabled"
                    />
                  </div>
                  <div className="mb-3">
                    <label for="disabledSelect">Disabled select menu</label>
                    <select
                      id="disabledSelect"
                      className="form-control"
                      disabled="disabled"
                    >
                      <option>Disabled select</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="my-1 me-2" for="country">Country</label>
                    <select
                      className="form-select"
                      id="country"
                      aria-label="Default select example"
                    >
                      <option selected="selected">Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="formFile" className="form-label"
                    >Default file input example</label
                    >
                    <input className="form-control" type="file" id="formFile" />
                  </div>
                </div>
              </div>
              <div className="row mb-5 mb-lg-5">
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <span className="h6 fw-bold">Checkboxes Round</span>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck10"
                    />
                    <label className="form-check-label" for="defaultCheck10"
                    >Default checkbox</label
                    >
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck20"
                      disabled="disabled"
                    />
                    <label className="form-check-label" for="defaultCheck20"
                    >Disabled checkbox</label
                    >
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 mt-4 mt-md-0">
                  <fieldset>
                    <legend className="h6">Radios</legend>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="option1"
                        checked="checked"
                      />
                      <label className="form-check-label" for="exampleRadios1"
                      >Default radio</label
                      >
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                      />
                      <label className="form-check-label" for="exampleRadios2"
                      >Second default radio</label
                      >
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios3"
                        value="option3"
                        disabled="disabled"
                      />
                      <label className="form-check-label" for="exampleRadios3"
                      >Disabled radio</label
                      >
                    </div>
                  </fieldset>
                </div>
                <div className="col-lg-3 col-sm-6 mt-4 mt-md-0">
                  <div className="mb-3">
                    <span className="h6 fw-bold">Switches</span>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                    <label className="form-check-label" for="flexSwitchCheckDefault"
                    >Default switch input</label
                    >
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked="checked"
                    />
                    <label className="form-check-label" for="flexSwitchCheckChecked"
                    >Checked switch input</label
                    >
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDisabled"
                      disabled="disabled"
                    />
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckDisabled"
                    >Disabled switch input</label
                    >
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckCheckedDisabled"
                      checked="checked"
                      disabled="disabled"
                    />
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckCheckedDisabled"
                    >Disabled checked switch input</label
                    >
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-12 col-md-5">
                  <div className="mb-6">
                    <span className="h6 fw-bold">Simple Range Slider</span>
                  </div>
                  <div className="input-slider-container">
                    <div
                      id="input-slider-forms"
                      className="input-slider"
                      data-range-value-min="100"
                      data-range-value-max="500"
                    ></div>
                    <div className="row mt-3 d-none">
                      <div className="col-6">
                        <span
                          className="range-slider-value"
                          data-range-value-low="200"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  <div className="mb-6">
                    <span className="h6 fw-bold">Range Slider with numbers</span>
                  </div>
                  <div
                    id="input-slider-range"
                    data-range-value-min="100"
                    data-range-value-max="500"
                  ></div>
                  <div className="row d-none">
                    <div className="col-6">
                      <span
                        className="range-slider-value value-low"
                        data-range-value-low="200"
                        id="input-slider-range-value-low"
                      ></span>
                    </div>
                    <div className="col-6 text-right">
                      <span
                        className="range-slider-value value-high"
                        data-range-value-high="400"
                        id="input-slider-range-value-high"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="row">
        <div className="col-12 mb-4">
          <div className="card border-0 shadow">
            <div className="card-body">
              <div className="row d-block mt-4">
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-default"
                  >
                    Default
                  </button>
                  <div
                    className="modal fade"
                    id="modal-default"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-default"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h2 className="h6 modal-title">Terms of Service</h2>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <p>
                            With less than a month to go before the European
                            Union enacts new consumer privacy laws for its
                            citizens, companies around the world are updating
                            their terms of service agreements to comply.
                          </p>
                          <p>
                            The European Union’s General Data Protection
                            Regulation (G.D.P.R.) goes into effect on May 25 and
                            is meant to ensure a common set of data rights in
                            the European Union. It requires organizations to
                            notify users as soon as possible of high-risk data
                            breaches that could personally affect them.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary">
                            Accept
                          </button>
                          <button
                            type="button"
                            className="btn btn-link text-gray-600 ms-auto"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-notification"
                  >
                    Notification
                  </button>
                  <div
                    className="modal fade"
                    id="modal-notification"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-notification"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-info modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content bg-gradient-secondary">
                        <button
                          type="button"
                          className="btn-close theme-settings-close fs-6 ms-auto"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                        <div className="modal-header">
                          <p
                            className="modal-title text-gray-200"
                            id="modal-title-notification"
                          >
                            A new experience, personalized for you.
                          </p>
                        </div>
                        <div className="modal-body text-white">
                          <div className="py-3 text-center">
                            <span className="modal-icon"
                            ><svg
                              className="icon icon-xl text-gray-200"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                  d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
                                ></path>
                                <path
                                  d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
                                ></path></svg
                              ></span>
                            <h2 className="h4 modal-title my-3">
                              Important message!
                            </h2>
                            <p>
                              Do you know that you can assign status and
                              relation to a company right in the visit list?
                            </p>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-sm btn-white">
                            Go to Inbox
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-form"
                  >
                    Sign In
                  </button>
                  <div
                    className="modal fade"
                    id="modal-form"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body p-0">
                          <div className="card p-3 p-lg-4">
                            <button
                              type="button"
                              className="btn-close ms-auto"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                            <div
                              className="text-center text-md-center mb-4 mt-md-0"
                            >
                              <h1 className="mb-0 h4">Sign in to our platform</h1>
                            </div>
                            <form action="#" className="mt-4">
                              <div className="form-group mb-4">
                                <label for="email">Your Email</label>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  ><svg
                                    className="icon icon-xs text-gray-600"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                                      ></path>
                                      <path
                                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                      ></path>
                                    </svg> </span
                                  ><input
                                    type="email"
                                    className="form-control"
                                    placeholder="example@company.com"
                                    id="email"
                                    autofocus
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-group mb-4">
                                  <label for="password">Your Password</label>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    ><svg
                                      className="icon icon-xs text-gray-600"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                          fillRule="evenodd"
                                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg> </span
                                    ><input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                      id="password"
                                      required
                                    />
                                  </div>
                                </div>
                                <div
                                  className="d-flex justify-content-between align-items-top mb-4"
                                >
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="remember"
                                    />
                                    <label
                                      className="form-check-label mb-0"
                                      for="remember"
                                    >Remember me</label
                                    >
                                  </div>
                                  <div>
                                    <Link
                                      href="forgot-password.html"
                                      className="small text-right"
                                    >Lost password?</Link
                                    >
                                  </div>
                                </div>
                              </div>
                              <div className="d-grid">
                                <button type="submit" className="btn btn-gray-800">
                                  Sign in
                                </button>
                              </div>
                            </form>
                            <div className="mt-3 mb-4 text-center">
                              <span className="fw-normal">or login with</span>
                            </div>
                            <div className="d-flex justify-content-center my-4">
                              <Link
                                to="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500 me-2"
                                type="button"
                                aria-label="facebook button"
                                title="facebook button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="facebook-f"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                  <path
                                    fill="currentColor"
                                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                  ></path>
                                </svg> </Link>
                              <Link
                                to="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500 me-2"
                                type="button"
                                aria-label="twitter button"
                                title="twitter button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="twitter"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                  <path
                                    fill="currentColor"
                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                  ></path>
                                </svg> </Link>
                              <Link
                                to="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500"
                                type="button"
                                aria-label="github button"
                                title="github button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="github"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 496 512">
                                  <path
                                    fill="currentColor"
                                    d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                                  ></path></svg></Link>
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center mt-4" >
                              <span className="fw-normal">Not registered?
                                <Link to="#" className="fw-bold">Create account</Link></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-form-signup"
                  >
                    Sign Up
                  </button>
                  <div
                    className="modal fade"
                    id="modal-form-signup"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form-signup"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body p-0">
                          <div className="card p-3 p-lg-4">
                            <button
                              type="button"
                              className="btn-close ms-auto"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                            <div
                              className="text-center text-md-center mb-4 mt-md-0"
                            >
                              <h1 className="mb-0 h4">Create Account</h1>
                            </div>
                            <form action="#" className="mt-4">
                              <div className="form-group mb-4">
                                <label for="email">Your Email</label>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  ><svg
                                    className="icon icon-xs text-gray-600"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                                      ></path>
                                      <path
                                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                                      ></path>
                                    </svg> </span
                                  ><input
                                    type="email"
                                    className="form-control"
                                    placeholder="example@company.com"
                                    id="email"
                                    autofocus
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-group mb-4">
                                  <label for="password">Your Password</label>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    ><svg
                                      className="icon icon-xs text-gray-600"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                          fillRule="evenodd"
                                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg> </span
                                    ><input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                      id="password"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-4">
                                  <label for="confirm_password"
                                  >Confirm Password</label
                                  >
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    ><svg
                                      className="icon icon-xs text-gray-600"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                          fillRule="evenodd"
                                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                          clipRule="evenodd"
                                        ></path>
                                      </svg> </span
                                    ><input
                                      type="password"
                                      placeholder="Confirm Password"
                                      className="form-control"
                                      id="confirm_password"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="remember"
                                    />
                                    <label
                                      className="form-check-label fw-normal mb-0"
                                      for="remember"
                                    >I agree to the
                                      <Link href="#" className="fw-bold"
                                      >terms and conditions</Link
                                      ></label
                                    >
                                  </div>
                                </div>
                              </div>
                              <div className="d-grid">
                                <button type="submit" className="btn btn-gray-800">
                                  Sign up
                                </button>
                              </div>
                            </form>
                            <div className="mt-3 mb-4 text-center">
                              <span className="fw-normal">or login with</span>
                            </div>
                            <div className="d-flex justify-content-center my-3">
                              <Link
                                href="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500 me-2"
                                type="button"
                                aria-label="facebook button"
                                title="facebook button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="facebook-f"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                              >
                                  <path
                                    fill="currentColor"
                                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                  ></path>
                                </svg> </Link
                              ><Link
                                href="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500 me-2"
                                type="button"
                                aria-label="twitter button"
                                title="twitter button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="twitter"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                  <path
                                    fill="currentColor"
                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                  ></path>
                                </svg> </Link
                              ><Link
                                href="#"
                                className="btn btn-icon-only btn-pill btn-outline-gray-500"
                                type="button"
                                aria-label="github button"
                                title="github button"
                              ><svg
                                className="icon icon-xxs"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="github"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 496 512"
                              >
                                  <path
                                    fill="currentColor"
                                    d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                                  ></path></svg
                                ></Link>
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center mt-4"
                            >
                              <span className="fw-normal"
                              >Already have an account?
                                <Link href="sign-in.html" className="fw-bold"
                                >Login here</Link
                                ></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-achievement"
                  >
                    Achievement
                  </button>
                  <div
                    className="modal fade"
                    id="modal-achievement"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-achievement"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-tertiary modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <button
                          type="button"
                          className="btn-close theme-settings-close fs-6 ms-auto"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                        <div className="modal-header mx-auto">
                          <p className="lead mb-0 text-white">
                            You just unlocked a new badge
                          </p>
                        </div>
                        <div className="modal-body pt-0">
                          <div className="py-3 px-5 text-center">
                            <span className="modal-icon display-1 text-white"
                            ><svg
                              className="icon icon-lg text-gray-200"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                  fillRule="evenodd"
                                  d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                                  clipRule="evenodd"
                                ></path></svg
                              ></span>
                            <h2 className="h3 modal-title mb-3 text-white">
                              Author Level 5
                            </h2>
                            <p className="mb-4 text-white">
                              One Thousand Dollars! Well done mate - heads are
                              turning your way.
                            </p>
                            <div className="progress mb-0">
                              <div
                                className="progress-bar bg-secondary"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="modal-footer d-flex justify-content-center pt-0 pb-3"
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-white text-tertiary"
                            data-bs-dismiss="modal"
                          >
                            Awesome!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-block btn-gray-800 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-subscribe"
                  >
                    Subscribe
                  </button>
                  <div
                    className="modal fade"
                    id="modal-subscribe"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-subscribe"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-tertiary modal-dialog-centered modal-lg"
                      role="document"
                    >
                      <div className="modal-content bg-dark text-white">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close btn-close-white text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body text-center py-3">
                          <span className="modal-icon"
                          ><svg
                            className="icon icon-xl text-gray-200 mb-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                              <path
                                fillRule="evenodd"
                                d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z"
                                clipRule="evenodd"
                              ></path></svg
                            ></span>
                          <h3 className="modal-title mb-3">
                            Join our 1,360,462 subscribers
                          </h3>
                          <p className="mb-4 lead">
                            Get exclusive access to freebies, premium products
                            and news.
                          </p>
                          <div className="form-group px-lg-5">
                            <div className="d-flex mb-3 justify-content-center">
                              <input
                                type="text"
                                id="subscribe"
                                className="me-sm-1 mb-sm-0 form-control form-control-lg"
                                placeholder="example@company.com"
                              />
                              <div>
                                <button
                                  type="submit"
                                  className="ms-2 btn large-form-btn btn-secondary"
                                >
                                  Subscribe
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer z-2 mx-auto text-center">
                          <p className="text-white font-small">
                            We’ll never share your details with third
                            parties.<br className="visible-md" />View our
                            <Link href="#">Privacy Policy</Link> for more info.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row">
        <div className="col-12 mb-4">
          <div className="card border-0 shadow components-section">
            <div className="card-body">
              <div className="row mb-4 mb-lg-5">
                <div className="col-12 col-md-6">
                  <h2 className="h5 mb-3">Headings</h2>
                  <h1>h1. Themesberg heading</h1>
                  <h2>h2. Themesberg heading</h2>
                  <h3>h3. Themesberg heading</h3>
                  <h4>h4. Themesberg heading</h4>
                  <h5>h5. Themesberg heading</h5>
                  <h6>h6. Themesberg heading</h6>
                </div>
                <div className="col-12 col-md-6">
                  <h2 className="h5 mb-3">Display Headings</h2>
                  <h1 className="display-1">Display 1</h1>
                  <h1 className="display-2">Display 2</h1>
                  <h1 className="display-3">Display 3</h1>
                  <h1 className="display-4">Display 4</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-5"><h2 className="h5 mb-3">Paragraphs</h2></div>
                </div>
              </div>
              <div className="row mb-4 mb-lg-5">
                <div className="col-md-6">
                  <div className="mb-3">
                    <span className="h6 fw-bold">Simple paragraph</span>
                  </div>
                  <p>
                    Start your development with a Pixel Design System for
                    Bootstrap 4. Themesberg makes beautiful products to help
                    people with creative ideas succeed.Our company empowers
                    millions of people.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="mt-5 mb-3 mt-sm-0">
                    <span className="h6 fw-bold">Lead paragraph</span>
                  </div>
                  <p className="lead">
                    Start your development with a Pixel Design System for
                    Bootstrap 4.Themesberg makes beautiful products to help
                    people with creative ideas succeed.Our company empowers
                    millions of people.
                  </p>
                </div>
              </div>
              <div className="row mt-4 mb-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Dark text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-dark mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Primary text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-primary mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted"
                  >Secondary text</small
                  >
                </div>
                <div className="col-sm-10">
                  <p className="text-secondary mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Tertiary text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-tertiary mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Info text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-info mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Danger text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-danger mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row py-3 align-items-center">
                <div className="col-sm-2">
                  <small className="text-uppercase text-muted">Success text</small>
                </div>
                <div className="col-sm-10">
                  <p className="text-success mb-0">
                    Design is not just what it looks like and feels like. Design
                    is how it works.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mt-6 mb-5">
                    <span className="h6">Blockquote</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <blockquote className="blockquote text-center">
                    Themesberg makes beautiful products to help people with
                    creative ideas succeed. Our company empowers millions of
                    people.
                    <footer className="blockquote-footer mt-3 text-primary">
                      Zoltan Szőgyényi
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mt-6 mb-5"><span className="h6">Lists</span></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3"><span className="h6">Unordered</span></div>
                  <ul>
                    <li>Minutes of the last meeting</li>
                    <li>Do we need yet more meetings?</li>
                    <li>
                      Any other business
                      <ul>
                        <li>Programming</li>
                        <li>Web Design</li>
                        <li>Database</li>
                      </ul>
                    </li>
                    <li>Something funny</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="mt-5 mb-3 mt-sm-0">
                    <span className="h6 fw-bold">Ordered</span>
                  </div>
                  <ol>
                    <li>Minutes of the last meeting</li>
                    <li>Do we need yet more meetings?</li>
                    <li>
                      Any other business
                      <ol>
                        <li>Programming</li>
                        <li>Web Design</li>
                        <li>Database</li>
                      </ol>
                    </li>
                    <li>Something funny</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageNavigation main="Componen List" sub="Home" header="The End" description="List of components" link="/" linkName="Detail" />



    </React.Fragment>
  );
}
