import React, {useState, useEffect, useContext} from 'react'
import MenuItem from "./MenuItem";
import routes from "../../../configs/route";
import {Link, Navigate, useLocation} from "react-router-dom";
import {AppContext} from "../../../configs/AppContextProvider";
import logo from '../../../assets/light.svg';


export default function MainMenu() {
    let location = useLocation();
    const [visibility, setVisibility] = useState(true);
    const {user, setUser, tenant, setTenant} = useContext(AppContext);
    const [isLogin, setIsLogin] = useState(true);
    const [currentRole, setCurrentRole] = useState("ADMIN");

    useEffect(() => {
        if (!user) {
            const read_user = localStorage.getItem('user_data')
            if (read_user) {
                setIsLogin(true)
                setUser(read_user)
            } else {
               // navigate("/sing-in");
            }
        } else {
            setIsLogin(true)
        }
        if (tenant === null) {
            const read_tenant = localStorage.getItem('selectedTenant')
            if (read_tenant) {
                setTenant(JSON.parse(read_tenant))
            }
        }
    }, []);


    useEffect(() => {
        if (localStorage.getItem("user_data")) {
            setCurrentRole(JSON.parse(localStorage.getItem("user_data")).role)
        }
    }, []);

    useEffect(() => {
        if (location.pathname === "/sing-in") {
            setVisibility(false)
        } else {
            setVisibility(true)
        }
    }, [location]);


    return (<React.Fragment>
            {visibility === true ? <nav
                id="sidebarMenu"
                className="sidebar d-lg-block bg-gray-800 text-white collapse"

            >
                <div className="sidebar-inner px-4 pt-3">
                    <ul className="nav flex-column pt-3 pt-md-0">
                        <li className="nav-item mb-3">
                            <Link
                                to="/"
                                className="nav-link d-flex align-items-center"
                            >
                    <span className="sidebar-icon">
                      <img
                          src={logo}
                          height="20"
                          width="20"
                          alt="Elta Logo"
                      />
                    </span>
                                <span className="mt-1 sidebar-text">Elta</span>
                            </Link>
                        </li>
                        {
                            currentRole ?
                                routes.map((route, key) => route.auth.includes(currentRole) && route.sidePanelVisible === true ? (
                                    <MenuItem
                                        key={key}
                                        icon={route.icon}
                                        name={route.name}
                                        path={route.path}
                                    />) : null)
                                : null
                        }
                    </ul>
                </div>
            </nav> : null}
            {isLogin === false ? <Navigate to="/sing-in"/> : null}
        </React.Fragment>

    );
}
