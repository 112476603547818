import React, {useState, useEffect, useContext} from 'react'
import MenuItem from "./MenuItem";
import routes from "../../../configs/route";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../../configs/AppContextProvider";
import logo from '../../../assets/light.svg';


export default function MainMenu() {
    let location = useLocation();
    const [visibility, setVisibility] = useState(true);
    const {user, setUser, tenant, setTenant} = useContext(AppContext);
    const [isLogin, setIsLogin] = useState(true);
    const navigate = useNavigate();
    const [currentRole, setCurrentRole] = useState("ADMIN");

    useEffect(() => {
        if (!user) {
            const read_user = localStorage.getItem('user_data')
            if (read_user) {
                setIsLogin(true)
                setUser(read_user)
            } else {
               // navigate("/sing-in");
            }
        } else {
            setIsLogin(true)
        }
        if (tenant === null) {
            const read_tenant = localStorage.getItem('selectedTenant')
            if (read_tenant) {
                setTenant(JSON.parse(read_tenant))
            }
        }
    }, []);


    useEffect(() => {
        if (localStorage.getItem("user_data")) {
            setCurrentRole(JSON.parse(localStorage.getItem("user_data")).role)
        }
    }, []);

    useEffect(() => {
        if (location.pathname === "/sing-in") {
            setVisibility(false)
        } else {
            setVisibility(true)
        }
    }, [location]);


    return (<React.Fragment>
            {visibility === true ? <nav
                id="sidebarMenu"
                className="sidebar d-lg-block bg-gray-800 text-white collapse"
                data-simplebar
            >
                <div className="sidebar-inner px-4 pt-3">
                    <ul className="nav flex-column pt-3 pt-md-0">
                        <li className="nav-item mb-3">
                            <Link
                                to="/"
                                className="nav-link d-flex align-items-center"
                            >
                    <span className="sidebar-icon">
                      <img
                          src={logo}
                          height="20"
                          width="20"
                          alt="Elta Logo"
                      />
                    </span>
                                <span className="mt-1 sidebar-text">Elta</span>
                            </Link>
                        </li>
                        {
                            currentRole ?
                                routes.map((route, key) => route.auth.includes(currentRole) && route.sidePanelVisible === true ? (
                                    <MenuItem
                                        key={key}
                                        icon={route.icon}
                                        name={route.name}
                                        path={route.path}
                                    />) : null)
                                : null
                        }
                        <li
                            role="separator"
                            className="dropdown-divider mt-4 mb-3 border-gray-700"
                        ></li>
                        <li className="nav-item">
                            <Link
                                to="https://sinav.eltaexams.com/"
                                target="_blank"
                                className="nav-link d-flex align-items-center">
                    <span className="sidebar-icon">
                      <svg
                          className="icon icon-xs me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                                <span className="sidebar-text">
                      Documentation
                      <span className="badge bg-secondary ms-1 text-gray-800 badge-sm">
                        v1.4
                      </span>
                    </span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="https://sinav.eltaexams.com/"
                                target="_blank"
                                className="nav-link d-flex align-items-center"
                            >
                    <span className="sidebar-icon">
                      <svg
                          className="icon icon-xs me-2"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                                <span className="sidebar-text">Support</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="https://sinav.eltaexams.com/"
                                target="_blank"
                                className="nav-link d-flex align-items-center"
                            >
                    <span className="sidebar-icon">
                      <img
                          src="./assets/img/themesberg.svg"
                          height="20"
                          width="28"
                          alt="Themesberg Logo"
                      />
                    </span>
                                <span className="sidebar-text">Elta</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav> : null}
            {isLogin === false ? <Navigate to="/sing-in"/> : null}
        </React.Fragment>

    );
}
