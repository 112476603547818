import React, {useEffect, useState} from "react";
import PageNavigation from "../components/PageNavigation";
import {Link} from "react-router-dom";

import ExamService from "../../service/exam.service";
import {TiTick} from "react-icons/ti";
import {MdOutlineAirplanemodeInactive} from "react-icons/md";
import {RiSlideshow2Fill} from "react-icons/ri";
import {PiStudent} from "react-icons/pi";

export default function Exam() {
    const [examList, setExamList] = useState([]);
    const [filterType, setFilterType] = useState(0);
    localStorage.removeItem("qNumber");
    useEffect(() => {
        ExamService.getAllExam().then((response) => {
            setExamList(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const examRow = () => {
        return (
            examList && Array.isArray(examList) ?
                examList.map((exam, key) =>

                    filterType === 0 || (filterType === 1 && exam.status) || (filterType === 2 && !exam.status) ?
                        <tr key={key}>
                            <td>
                                <Link to={`/exam/${exam.id}`} className="d-flex align-items-center"
                                >
                                    <div
                                        className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                                    >
                                        <span>{key + 1}</span>
                                    </div>
                                    <div className="d-block">
                                        <span className="fw-bold">{exam.name}</span>
                                        <div className="small text-gray">
                                            <span className="__cf_email__">{exam.explain}</span>
                                        </div>
                                    </div>
                                </Link>
                            </td>
                            <td>
                                {
                                    exam.status === "ACTIVE" ?
                                        <span className="fw-normal text-success"> <TiTick/>Active</span> :
                                        <span className="fw-normal text-danger"> <MdOutlineAirplanemodeInactive/>Inactive</span>
                                }
                            </td>
                            <td>
                                <Link
                                    to={`/exam/create/${exam.id}`}
                                    className="btn btn-icon-only btn-youtube d-inline-flex align-items-center"
                                    type="button">
                                    <RiSlideshow2Fill/>
                                </Link
                                >
                            </td>
                            <td>
                                <Link
                                    to={`/applications/${exam.id}`}
                                    className="btn btn-icon-only d-inline-flex align-items-center"
                                    type="button">
                                    <PiStudent />
                                </Link
                                >
                            </td>
                        </tr> : null
                )
                : null
        )
    }

    return (
        <React.Fragment>

            <PageNavigation main="Componen List" sub="Home" header="Exam List" description="List of exams"
                            btnLink="/exam/create/new"
                            linkName="Create New Exam"/>
            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">
            <div className="d-flex mb-3">
                    <select
                        className="form-select fmxw-200"
                        aria-label="Message select example" onChange={(e) => setFilterType(Number(e.target.value))}>

                        <option value={0} selected>All</option>
                        <option value={1}>Active</option>
                        <option value={2}>Inactive</option>

                    </select>
                    <button className="btn btn-sm px-3 btn-secondary ms-3">Apply</button>
                </div>
                <table className="table user-table table-hover align-items-center">
                    <thead>
                    <tr>
                        <th className="border-bottom">Name</th>


                        <th className="border-bottom">Status</th>
                        <th className="border-bottom"></th>
                        <th className="border-bottom"></th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        examRow()
                    }

                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}