import React from "react";
import PlayerMy from "../../player/PlayerMy";
import config from "../../../configs/config.json";

export const selectAudio = (ques) => {
    return (
        <React.Fragment>
            {
                ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                    <h5>{ques.title}</h5>
                </div> : null
            }
            <div className="d-flex align-items-center justify-content-center m-2">
                {
                    <PlayerMy format="mp3" key={ques.id} url={`${config.api.invokeUrl}/storage/preview/file/${ques.url}`} handleEnded={(e)=>{}}
                              activityPArtId={ques.id}/>
                }
            </div>

            {
                ques.content ? <div className="d-flex align-items-center justify-content-center m-2">
                    <h5>{ques.content}</h5>
                </div> : null
            }
        </React.Fragment>
    )
}
export const selectImage = (ques) => {
    return (
        <React.Fragment>
            {
                ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                    <h5>{ques.title}</h5>
                </div> : null
            }
            <div className={"text-center"}>
                <img src={`${config.api.invokeUrl}/storage/preview/file/${ques.url}`} style={{height: "250px"}} alt={ques.title}/>
            </div>
        </React.Fragment>
    )
}
export const selectVideo = (ques) => {
    return (
        <React.Fragment>
            {
                ques.title ? <div className="d-flex align-items-center justify-content-center m-2">
                    <h5>{ques.title}</h5>
                </div> : null
            }
            <div className={"d-flex justify-content-center align-items-center mb-2"}>
                <PlayerMy format="mp4" key={ques.id} title={ques.title} url={`${ques.url}`} style={{height: "250px"}} handleEnded={(e)=>{}}/>
            </div>
        </React.Fragment>
    )
}
export const selectText = (ques) => {
    const lineList = ques.content.split("\n");
    return (
        <div className={"text-left"}>
            <h5>{
                lineList.map((line, key) =>
                    <div key={key}>{line}</div>
                )
            }
            </h5>
        </div>
    )
}