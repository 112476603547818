import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import React from "react";
import ExamService from "../../service/exam.service";
import SpeechText from "../../spech/SpeechText";
import PlayerMy from "../../player/PlayerMy";
import {MdAddCircleOutline} from "react-icons/md";
import {RiDeleteBin6Line, RiFileEditFill} from "react-icons/ri";
import {FaRegEdit} from "react-icons/fa";
import {FaDownLong, FaUpLong} from "react-icons/fa6";
import {AiOutlineSolution} from "react-icons/ai";
import {BsListColumnsReverse} from "react-icons/bs";

export default function DoExam() {
    const [exam, setExam] = useState(null);
    const [qNumber, setQNumber] = useState(-1);
    const [question, setQuestion] = useState(null);


    const [showGains, setShowGains] = useState(true);
    const [showEdit, setShowEdit] = useState(true);
    const [showAnswers, setShowAnswers] = useState(true);


    let {id} = useParams();

    useEffect(() => {
        ExamService.getExamById(id).then((response) => {
            setExam(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (exam && exam.activities && Array.isArray(exam.activities) && exam.activities.length > 0 && qNumber === -1) {
            setQNumber(0)
            setQuestion(exam.activities[0])

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);

    useEffect(() => {
        if (qNumber > -1) {
            setQuestion(exam.activities[qNumber])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qNumber]);

    const handleSelect = (value) => {
        question.answer = value
        setQuestion(JSON.parse(JSON.stringify(question)))

    }

    const handleQuestionSelect = (value) => {
        if (value === -1 && qNumber > 0) {
            setQNumber(qNumber - 1)
        } else if (value === 1 && qNumber < exam.activities.length - 1) {
            setQNumber(qNumber + 1)
        }
    }

    const getSpeechText = (text, questionNumber) => {
        console.log(text, questionNumber)
    };


    const selectAudio = (url) => {
        return (
            <div className={"text-center"}>



                <audio
                    controls
                    style={{padding: 1, width: "50%"}}
                >
                    <source
                        src={`http://localhost:3000/sample/${url}`}
                        type="audio/mpeg"
                    />
                </audio>


            </div>
        )
    }

    const selectImage = (url) => {
        return (
            <div className={"text-center"}>
                <img src={`http://localhost:3000/sample/${url}`} style={{height: "250px"}} alt="image"/>
            </div>
        )
    }
    const selectVideo = (url) => {
        return (
            <div className={"d-flex justify-content-center align-items-center mb-2"}>
                {
                    // <ReactPlayer playsinline={true} controls url={`http://localhost:3000/sample/${url}`}/>
                    //                 <CustomPlayer url={`http://localhost:3000/sample/${url}`}/>
                }

                <PlayerMy url={`http://localhost:3000/sample/${url}`} style={{height: "250px"}}/>
            </div>
        )
    }

    const selectText = (content) => {

        const lineList = content.split("\n");
        return (
            <div className={"text-left"}>
                <h5>{
                    lineList.map((line, key) =>
                        <div key={key}>{line}</div>
                    )
                }
                </h5>
            </div>
        )
    }


    const compare = (a, b) => {
        if (a.orderNumber < b.orderNumber) {
            return -1;
        }
        if (a.orderNumber > b.orderNumber) {
            return 1;
        }
        return 0;
    };


    console.log(question)


// <Link to={`/exam/${id}/${question.id}/${activityPart.id}`}> <FaRegEdit cursor={"pointer"} size={24} onClick={() => handleQuestionSelect(-1)} className="m-2"/></Link>
    const updateButtons = (key, activityPart) => {


        return (
            <>
                <div className="d-flex justify-content-end align-items-center mb-2">



                    <Link to={`/exam/${id}/${question.id}/${activityPart.id}`}> <FaRegEdit cursor={"pointer"} size={24}
                                                                                           className="m-2"/></Link>
                    <FaUpLong cursor={"pointer"} size={24} onClick={() => handleQuestionSelect(-1)} className="m-2"/>
                    <FaDownLong cursor={"pointer"} size={24} onClick={() => handleQuestionSelect(-1)} className="m-2"/>
                    <RiDeleteBin6Line cursor={"pointer"} size={24} color={"red"}
                                      onClick={() => handleQuestionSelect(-1)} className="m-2"/>
                </div>
                <hr/>


            </>
        )
    }


    const updateActivityButtons = () => {


        return (
            <>
                <div className="d-flex justify-content-end align-items-center mb-2 mr-4">
                    <Link to={`/exam/${id}/new`}> <MdAddCircleOutline cursor={"pointer"} size={28}
                                                                      className="m-2"/></Link>
                    {
                        question ?
                            <>
                                <Link to={`/exam/${id}/${question.id}`}><FaRegEdit cursor={"pointer"} size={28}
                                                                                   className="m-2"/></Link>
                                <RiDeleteBin6Line cursor={"pointer"} size={28} color={"red"}
                                                  onClick={() => handleQuestionSelect(-1)} className="m-2"/>
                            </>
                            : null
                    }

                </div>

                <div className="d-flex justify-content-start align-items-center mb-2 mr-4">
                    <AiOutlineSolution cursor={"pointer"} size={28} color={showAnswers ? "orange" : "gray"}
                                       onClick={() => setShowAnswers(!showAnswers)} className="m-2"/>
                    <RiFileEditFill cursor={"pointer"} size={28} color={showEdit ? "orange" : "gray"}
                                    onClick={() => setShowEdit(!showEdit)} className="m-2"/>
                    <BsListColumnsReverse cursor={"pointer"} size={28} color={showGains ? "orange" : "gray"}
                                          onClick={() => setShowGains(!showGains)} className="m-2"/>
                </div>


            </>
        )
    }


    const makeChoice = (key, ques) => {
        return (
            //<div className={`form-check alert alert-light border ${question.answer === key + "" ? "border-info" : "border-success"}  w-100`} role="alert">
            <div
                className={`form-check ${ques.isMain || showEdit ? "alert alert-light border " : "mb-3 "} ${question.answer === key + "" ? "border-info" : "border-success"}  w-100`}>

                {
                    showEdit ?
                        updateButtons(key, ques) : null
                }


                {
                    ques.type === "TEXT" ? selectText(ques.content) :
                        ques.type === "AUDIO" ? selectAudio(ques.url) :
                            ques.type === "VIDEO" ? selectVideo(ques.url) :
                                ques.type === "IMAGE" ? selectImage(ques.url) :
                                    null
                }


                {
                    ques.isMain ? <>
                            <SpeechText
                                getSpeechText={getSpeechText}
                                questionNumber={1}
                            />
                            {showAnswers ?
                                <div><b>{ques.answer}</b></div> : null
                            }
                        </> :
                        null

                }


                {
                    showGains && ques.gains && Array.isArray(ques.gains) && ques.gains.length > 0 ?
                        <>
                            <hr/>
                            {
                                ques.gains.map((gain, key) =>
                                    <div key={key} className="mt-2">
                                        <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                            <fieldset className="w-100">
                                                <div> {gain.skill} : {gain.name}</div>

                                            </fieldset>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                        : null
                }
            </div>
        )
    }

    return (<div>
        {
            question ?
                <React.Fragment>
                    <div className="row justify-content-center mt-3">
                        <div className="col-12">
                            <div className="card border-0 shadow p-4 mb-4">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="font-small">
                                      <Link to="#">
                                          <img
                                              className="avatar-sm img-fluid rounded-circle me-2"
                                              src="../assets/img/team/profile-picture-1.jpg"
                                              alt="avatar"/>
                                          <span className="fw-bold">{exam.name}</span>
                                    </Link>
                                      <span className="fw-normal ms-2">{exam.beginAt} - {exam.endAt}</span>
                                  </span>
                                    <div className="d-none d-sm-block">
                                        <svg
                                            className="icon icon-xs"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <p className="m-0">
                                    {exam.explain}
                                </p>
                            </div>
                        </div>
                    </div>
                </React.Fragment> : null}
        {
            updateActivityButtons()
        }
        <div className="d-flex justify-content-end align-items-center mb-2">


            <Link to={`/exam/${id}/${question?.id}/new`}> <MdAddCircleOutline cursor={"pointer"}
                                                                             size={32}
                                                                             className="m-2"/></Link></div>
            {
                question ?
                    <React.Fragment>
                        <div className="row justify-content-center mt-3">
                            <div className="col-12">
                                <div className="card border-0 shadow p-4 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="font-small">
                                          <h3 className="fw-bold">{(qNumber + 1) + " - "}{question.body}</h3>
                                  </span>
                                    </div>
                                    {
                                        question && question.parts && Array.isArray(question.parts) ?
                                            question.parts.sort(compare).map((ques, key) =>
                                                <div key={key} className="mt-2">
                                                    <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                                        <fieldset className="w-100">
                                                            {
                                                                makeChoice(key, ques)
                                                            }
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            )

                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination mb-0">
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handleQuestionSelect(-1)}>Previous
                                        </button>
                                    </li>

                                    {
                                        exam && exam.activities && Array.isArray(exam.activities) ?
                                            exam.activities.sort(compare).map((exam, key) =>
                                                <li key={key} className={`page-item`}>
                                                    <button
                                                        className={`page-link ${qNumber === key ? "bg-warning" : !exam.answer && exam.answer === "" ? "" : "bg-success"}`}
                                                        onClick={() => setQNumber(key)}>{key + 1}</button>
                                                </li>
                                            ) : null
                                    }
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handleQuestionSelect(1)}>Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </React.Fragment> : null}
        </div>
        )
        }