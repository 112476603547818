import React from "react";
import Button from "./Button";
import ButtonList from "./ButtonList";

export default function PanelHeader(props) {
  return (
    props.leftButton || props.buttons || props.items ?

    <div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4">
      {props.leftButton ? (
        <Button
          icon={props.leftButtonIcon}
          text={props.leftButton}
          link={props.leftButtonLink}
        />
      ) : null}
      <div className="d-block d-sm-flex">
        <div className="btn-group mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
          {props.buttons ? <Button buttons={props.buttons} items={props.items} /> : null}
        </div>
        {props.items ? (
          <ButtonList selectedText={props.itemText} items={props.items} />
        ) : null}
      </div>
    </div>
    : 
    <div className="d-flex justify-content-between align-items-center pb-2 pt-4 pb-md-4" style={{height:"89px"}}> 
        </div>

  );
}
