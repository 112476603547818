import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {FaPlayCircle} from "react-icons/fa";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    const {handleEnded, activityPArtId, title, format} = props;
    const [url, setUrl] = useState(props.url)
    const [playing, setPlaying] = useState(false)
    const audioImage = require('../../assets/dalga.png')
    const audioImagePlay = require('../../assets/dalga.gif')
    const [image, setImage] = useState(audioImage)
    const player = useRef(null)



    useEffect(() => {
        if (props.url) {
            setUrl(props.url)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    const handlePlay = () => {
        setPlaying(true)
    }


    return (
        <div className='app player-wrapper' style={{width: title === "WATCH THE VIDEO" ? "100%" : "50%"}}>
            <div className=''>
                {
                    format === "mp3" ?
                        <img src={image} alt={"audio"} style={{width: "100%"}}/>
                        :null
                }

                <ReactPlayer
                    ref={player}
                    className={`react-player`}
                    width='100%'
                    height={format === "mp3" ? "0px" : title === "WATCH THE VIDEO" ? "500px" : "100%"}
                    url={url}
                    pip={false}
                    playing={playing}
                    controls={true}
                    loop={false}
                    playbackRate={1.0}
                    onStart={() => {
                        setImage(audioImagePlay)
                    }}
                    onPlay={() => {
                        setImage(audioImagePlay)
                        handlePlay()
                    }}
                    onEnded={() => {
                        setImage(audioImage)
                        handleEnded(activityPArtId)
                    }}
                />

                <div className="d-flex justify-content-center">
                    <FaPlayCircle size={40} color={`${playing === true ? "gray" : "blue"}`}
                                  onClick={() => setPlaying(true)} style={{cursor: "pointer"}}/>
                </div>
            </div>
        </div>
    )

}
