import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import {ArrowUpDown} from 'lucide-react';
import _ from 'lodash';

export default function ExamResults(props) {
    let {examId} = useParams();

    const [data, setData] = useState([]);
    const [schools, setSchools] = useState([]);
    const [selectedSchoolId, setSelectedSchoolId] = useState('');
    const [processedData, setProcessedData] = useState([]);

    const [duration, setDuration] = useState(25);

    const [searchFilters, setSearchFilters] = useState({
        studentId: '',
        name: '',
        school: '',
        schoolId: '',
        grade: '',
        branch: '',
        status: ''
    });
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'asc'
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    useEffect(() => {

        let selectedExamId = props.examId;
        if(!selectedExamId)
            selectedExamId = examId;


        ExamService.getExamById(selectedExamId).then((res) => {
            console.log(res)
            setDuration(Number(res.duration));
            console.log(Number(res.duration))

            ExamService.getExamResults(selectedExamId).then((response) => {
                try {
                    setData(response);
                    const schoolsWithIds = response.map(item => ({
                        school: item.student.school,
                        schoolId: item.student.schoolId
                    }));
                    const uniqueSchools = _.uniqBy(schoolsWithIds, item => item.schoolId);
                    setSchools(uniqueSchools);
                } catch (error) {
                    console.error('Error reading file:', error);
                }
            });
        });



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.examId , examId]);

    useEffect(() => {
        let filtered = [...data];
        if (selectedSchoolId) {
            filtered = filtered.filter(item => item.student.schoolId === selectedSchoolId);
        }
        filtered = filtered.filter(item => {
            const status = item.examResult.duration === duration ? "BAŞLAMADI" :
                item.examResult.duration === 0 ? "BİTTİ" : "DEVAM EDİYOR";
            return (
                item.student.studentId.toLowerCase().includes(searchFilters.studentId.toLowerCase()) &&
                `${item.student.name} ${item.student.lastName}`.toLowerCase().includes(searchFilters.name.toLowerCase()) &&
                item.student.school.toLowerCase().includes(searchFilters.school.toLowerCase()) &&
                item.student.schoolId.toLowerCase().includes(searchFilters.schoolId.toLowerCase()) &&
                item.student.grade.toLowerCase().includes(searchFilters.grade.toLowerCase()) &&
                item.student.branch.toLowerCase().includes(searchFilters.branch.toLowerCase()) &&
                status.toLowerCase().includes(searchFilters.status.toLowerCase())
            );
        });

        if (sortConfig.key) {
            filtered = _.orderBy(
                filtered,
                [item => {
                    if (sortConfig.key === 'name') {
                        return `${item.student.name} ${item.student.lastName}`.toLowerCase();
                    }
                    if (sortConfig.key === 'status') {
                        return item.examResult.duration === duration ? "BAŞLAMADI" :
                            item.examResult.duration === 0 ? "BİTTİ" : "DEVAM EDİYOR";
                    }
                    return item.student[sortConfig.key].toLowerCase();
                }],
                [sortConfig.direction]
            );
        }
        setProcessedData(filtered);
    }, [data, selectedSchoolId, searchFilters, sortConfig, duration]);

    const handleSort = (key) => {
        setSortConfig({
            key,
            direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        });
    };

    const handleSearchChange = (key, value) => {
        setSearchFilters(prev => ({
            ...prev,
            [key]: value
        }));
        setCurrentPage(1);
    };

    const pageCount = Math.ceil(processedData.length / itemsPerPage);
    const paginatedData = processedData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    return (
        <div className="p-4">
            <div className="mb-6">
                <select
                    value={selectedSchoolId}
                    onChange={(e) => {
                        setSelectedSchoolId(e.target.value);
                        setCurrentPage(1);
                    }}
                    className="w-64 p-2 border rounded bg-white shadow-sm"
                >
                    <option value="">Tüm Okullar</option>
                    {schools.map((school, index) => (
                        <option key={index} value={school.schoolId}>
                            {school.school} (ID: {school.schoolId})
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-2 text-sm text-gray-600">
                Toplam {processedData.length} kayıt bulundu
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                    <thead>
                    <tr className="bg-gray-50">
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('studentId')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Öğrenci ID
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.studentId}
                                    onChange={(e) => handleSearchChange('studentId', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('name')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Adı Soyadı
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.name}
                                    onChange={(e) => handleSearchChange('name', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('school')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Okul
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.school}
                                    onChange={(e) => handleSearchChange('school', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('schoolId')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Okul ID
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.schoolId}
                                    onChange={(e) => handleSearchChange('schoolId', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('grade')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Sınıf
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.grade}
                                    onChange={(e) => handleSearchChange('grade', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('branch')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Şube
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.branch}
                                    onChange={(e) => handleSearchChange('branch', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                        <th className="px-4 py-2 border">
                            <div className="space-y-2">
                                <button
                                    onClick={() => handleSort('status')}
                                    className="flex items-center gap-2 w-full font-semibold"
                                >
                                    Durum
                                    <ArrowUpDown size={14}/>
                                </button>
                                <input
                                    type="text"
                                    value={searchFilters.status}
                                    onChange={(e) => handleSearchChange('status', e.target.value)}
                                    placeholder="Ara..."
                                    className="w-full p-1 text-sm border rounded"
                                />
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((item, index) => (
                        <tr
                            key={index}
                            className="hover:bg-gray-50"
                        >
                            <td className="px-4 py-2 border">{item.student.studentId}</td>
                            <td className="px-4 py-2 border">
                                {item.student.name} {item.student.lastName}
                            </td>
                            <td className="px-4 py-2 border">{item.student.school}</td>
                            <td className="px-4 py-2 border">{item.student.schoolId}</td>
                            <td className="px-4 py-2 border">{item.student.grade}</td>
                            <td className="px-4 py-2 border">{item.student.branch}</td>
                            <td className="px-4 py-2 border">
                                {item.examResult.duration === duration ? "BAŞLAMADI" :
                                    item.examResult.duration === 0 ? "BİTTİ" : "DEVAM EDİYOR"}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-4 flex justify-between items-center">
                <div className="text-sm text-gray-600">
                    Sayfa {currentPage} / {pageCount}
                </div>
                <div className="space-x-2">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="px-3 py-1 border rounded bg-white disabled:opacity-50"
                    >
                        Önceki
                    </button>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
                        disabled={currentPage === pageCount}
                        className="px-3 py-1 border rounded bg-white disabled:opacity-50"
                    >
                        Sonraki
                    </button>
                </div>
            </div>
        </div>
    );
};