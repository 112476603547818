import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import {NotificationManager} from "react-notifications";
import PageNavigation from "../components/PageNavigation";
import InputLink from "../components/InputLink";


const newExam = {
    id: -1,
    deletedAt: null,
    status: "ACTIVE",
    duration:25,
    name: "",
    explain: "",
    activities: []
}


export default function ExamEdit() {

    let {examId} = useParams();
    const navigate = useNavigate();
    const [selectedExam, setSelectedExam] = useState(newExam);

    localStorage.removeItem("qNumber");

    useEffect(() => {
        if (examId === "new") {
            setSelectedExam(newExam);
            return;
        }
        ExamService.getExamById(examId).then((response) => {
            setSelectedExam(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validation = () => {
        if (selectedExam.name === "") {
            NotificationManager.error("Name is required", "Error", 3000);
            return false;
        }
        if (selectedExam.duration === "") {
            NotificationManager.error("Duration is required", "Error", 3000);
            return false;
        }
        return true;
    }


    const saveActivityEvent = () => {
        if (validation()) {
            if (examId === "new") {
                ExamService.createExam(selectedExam).then((response) => {
                    if (response) {
                        NotificationManager.success("Created", "Success", 3000);
                        navigate("/");
                    }
                });
            } else {
                ExamService.updateExam(examId, selectedExam).then((response) => {
                    if (response) {
                        NotificationManager.success("Updated", "Success", 3000);
                        navigate("/");
                    }
                });
            }
        }
    }


    const deleteActivityEvent = () => {
        ExamService.deleteExam(examId).then((response) => {
            if (response) {
                NotificationManager.success("Deleted", "Success", 3000);
                navigate("/");
            }
        });
    }


    return (


        <div>
            <PageNavigation main="Componen List" sub="Home"
                            header={`${examId === "new" ? "Create New Exam" : "Edit Exam: " + selectedExam?.name}`}
                            description="List of exams"/>
            <div className="card card-body border-0 shadow my-3">
                <h2 className="h5 mb-4">Exam</h2>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div>
                            <label htmlFor="first_name">Status</label>
                            <select
                                className="form-select w-100 mb-0"
                                onChange={(e) => setSelectedExam({
                                    ...selectedExam,
                                    status: e.target.value
                                })}
                                value={selectedExam.status}
                            >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="PASSIVE">PASSIVE</option>
                            </select>

                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div>
                            <label htmlFor="last_name">Name</label>
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setSelectedExam({
                                    ...selectedExam,
                                    name: e.target.value
                                })}
                                value={selectedExam.name}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label htmlFor="email">Explain</label>
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setSelectedExam({
                                    ...selectedExam,
                                    explain: e.target.value
                                })}
                                value={selectedExam.explain}
                            />
                        </div>
                    </div>


                    <div className="col-md-12 mb-3">
                        <div className="form-group">
                            <label htmlFor="email">Duration</label>
                            <input
                                className="form-control"

                                type="number"
                                onChange={(e) => setSelectedExam({
                                    ...selectedExam,
                                    duration: e.target.value
                                })}
                                value={selectedExam.duration}
                            />
                        </div>
                    </div>


                </div>
                <div className="card-footer">

                    <InputLink clickEvent={() => saveActivityEvent()} name="Exam Save"
                               type="add"/>
                    <hr/>

                    {
                        examId !== "new" ?
                            <div className="text-end">
                                <InputLink clickEvent={deleteActivityEvent} name="Exam Delete"
                                           type="delete"/></div>

                            : null
                    }
                </div>
            </div>

        </div>


    )
}