import React from "react";
import { Link } from "react-router-dom";
import { IconHomeNavigation, IconPlus } from "./Icons";

export default function PageNavigation(props) {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
      <div className="d-block mb-4 mb-md-0">

        <h1 className="h4"> {props.header}</h1>
        <p className="mb-0">{props.description}</p>
      </div>

      <div className="btn-toolbar mb-2 mb-md-0">
        <Link
          to={props.btnLink}
          className="btn btn-sm btn-gray-800 d-inline-flex align-items-center"
        >
          <IconPlus />
          {props.linkName}
        </Link>
        {
          /*
          <div className="btn-group ms-2 ms-lg-3">
          <button
            type="button"
            className="btn btn-sm btn-outline-gray-600"
            onClick={props.btn1Click}
          >ssss
            {props.btn1}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-outline-gray-600"
            onClick={props.btn2Click}
          >ssss
            {props.btn2}
          </button>
        </div>
           */
        }

      </div>
    </div>
  );
}
