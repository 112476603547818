import React, {useState} from 'react'
import FormInput from "./components/FormInput";
import FormSelect from "./components/FormSelect";
import {IconAdd} from "./components/Icons";
import PanelHeader from "./components/PanelHeader";

export default function Report() {
    const [component, setComponent] = useState([]);
    const [type, setType] = useState([]);
    const [beginAt, setBeginAt] = useState("");
    const [endAt, setEndAt] = useState("");

    return (
        <React.Fragment>
            <PanelHeader
                leftButton="Export Pdf"
                leftButtonLink="/"
                leftButtonIcon={<IconAdd />}
            />
            <div className="row">
                <div className="col-12 col-xl-12">

                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className='p-2 m-2'>
                                <h5>Report</h5>
                                <hr/>
                                <div className='row'>
                                    <FormSelect
                                        name="component"
                                        text="Component"
                                        placeholder=""
                                        type="text"
                                        col="3"
                                        required
                                        value={component.id}
                                        onChange={(e) => setComponent(e.target.value)}
                                        id={"inputs.id"}
                                        data={component}
                                        valuekey="id"
                                        textkey="name"
                                    />

                                    <FormSelect
                                        name="type"
                                        text="Type"
                                        placeholder=""
                                        type="text"
                                        col="3"
                                        required
                                        value={type.id}
                                        onChange={(e) => setType(e.target.value)}
                                        id={"inputs.id"}
                                        data={type}
                                        valuekey="id"
                                        textkey="name"
                                    />
                                    <FormInput
                                        name="beginAt"
                                        text="Begin At"
                                        placeholder="Enter longitude"
                                        type="date"
                                        col="3"
                                        required
                                        value={beginAt}
                                        onChange={(e) => setBeginAt(e.target.value)}
                                    />
                                    <FormInput
                                        name="endAt"
                                        text="End At"
                                        placeholder="Enter longitude"
                                        type="date"
                                        col="3"
                                        required
                                        value={endAt}
                                        onChange={(e) => setEndAt(e.target.value)}
                                    />

                                </div>
                                <button
                                    className="btn btn-gray-800 m-3">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
