import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <main>
    <section className="vh-100 d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-12 col-lg-5 order-2 order-lg-1 text-center text-lg-left"
          >
            <h1 className="mt-5">
              Something has gone
              <span className="text-primary">seriously</span> wrong
            </h1>
            <p className="lead my-4">
              It's always time for a coffee break. We should be back by the
              time you finish your coffee.
            </p>
            <Link
              to="/"
              className="btn btn-gray-800 d-inline-flex align-items-center justify-content-center mb-4"
              ><svg
                className="icon icon-xs me-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              Back to homepage
              </Link>
          </div>
          <div
            className="col-12 col-lg-7 order-1 order-lg-2 text-center d-flex align-items-center justify-content-center"
          >
            <img
              className="img-fluid w-75"
              src="../../assets/img/illustrations/500.svg"
              alt="500 Server Error"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
  )
}
