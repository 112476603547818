import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import React from "react";
import ExamService from "../../service/exam.service";
import SpeechText from "../../spech/SpeechText";
import {RiFileEditFill} from "react-icons/ri";
import {FaRegEdit} from "react-icons/fa";
import {AiOutlineSolution} from "react-icons/ai";
import {BsListColumnsReverse} from "react-icons/bs";
import PageNavigation from "../components/PageNavigation";
import InputLink from "../components/InputLink";
import {selectAudio, selectImage, selectText, selectVideo} from "./template";


export default function DoExam() {
    const [exam, setExam] = useState(null);
    const [qNumber, setQNumber] = useState(-1);
    const [question, setQuestion] = useState(null);
    const [showGains, setShowGains] = useState(true);
    const [showEdit, setShowEdit] = useState(true);
    const [showAnswers, setShowAnswers] = useState(true);


    console.log(qNumber)

    let {id} = useParams();

    useEffect(() => {
        ExamService.getExamById(id).then((response) => {
            setExam(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (exam && exam.activities && Array.isArray(exam.activities) && exam.activities.length > 0 && qNumber === -1) {
            setQNumber(0)
            setQuestion(exam.activities[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);


    useEffect(() => {

        if (exam && exam.activities && Array.isArray(exam.activities) && exam.activities.length > 0 && qNumber === -1) {
            const localQData = localStorage.getItem("qNumber")
            if (localQData) {
                if (exam.activities.length > parseInt(localQData) && parseInt(localQData) > -1) {

                    console.log("BURADA 1")
                    setQNumber(parseInt(localQData))
                    setQuestion(exam.activities[parseInt(localQData)])
                } else {
                    console.log("BURADA 2")
                    setQNumber(0)
                    setQuestion(exam.activities[0])
                }
            } else {
                console.log("BURADA 3")
                setQNumber(0)
                setQuestion(exam.activities[0])
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exam]);

    useEffect(() => {
        if (qNumber > -1) {
            setQuestion(exam.activities[qNumber])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qNumber]);


    const handleQuestionSelect = (value) => {
        if (value === -1 && qNumber > 0) {
            setQNumber(qNumber - 1)
            localStorage.setItem("qNumber", qNumber - 1)
            console.log("SETLENDİ")
        } else if (value === 1 && qNumber < exam.activities.length - 1) {
            setQNumber(qNumber + 1)
            localStorage.setItem("qNumber", qNumber + 1)
            console.log("SETLENDİ")
        }
    }


    const getSpeechText = (text, questionNumber) => {
    };

    const compare = (a, b) => {
        if (a.orderNumber < b.orderNumber) {
            return -1;
        }
        if (a.orderNumber > b.orderNumber) {
            return 1;
        }
        return 0;
    };
    const updateButtons = (key, activityPart) => {


        return (
            <>
                <div className="d-flex justify-content-end align-items-center mb-2">
                    <Link to={`/exam/${id}/${question.id}/${activityPart.id}`}> <FaRegEdit cursor={"pointer"} size={24}
                                                                                           className="m-2"/></Link>


                </div>
                <hr/>
            </>
        )
    }
    const updateActivityButtons = () => {


        return (
            <>
                <div className="d-flex justify-content-end align-items-center mb-2 mr-4">
                    <InputLink to={`/exam/${id}/new`} name="Activity Add" type="add"/>
                    {
                        question ?
                            <>
                                <InputLink to={`/exam/${id}/${question.id}`} name="Activity Edit" type="edit"/>
                                <InputLink to={`/exam/${id}/${question.id}`} name="Activity Delete"
                                           type="delete"/>
                            </>
                            : null
                    }
                </div>
                <div className="d-flex justify-content-start align-items-center mb-2 mr-4">
                    <AiOutlineSolution cursor={"pointer"} size={28} color={showAnswers ? "orange" : "gray"}
                                       onClick={() => setShowAnswers(!showAnswers)} className="m-2"/>
                    <RiFileEditFill cursor={"pointer"} size={28} color={showEdit ? "orange" : "gray"}
                                    onClick={() => setShowEdit(!showEdit)} className="m-2"/>
                    <BsListColumnsReverse cursor={"pointer"} size={28} color={showGains ? "orange" : "gray"}
                                          onClick={() => setShowGains(!showGains)} className="m-2"/>
                </div>
            </>
        )
    }


    const makeChoice = (key, ques) => {
        return (
            <div
                className={`form-check ${ques.isMain || showEdit ? "alert alert-light border " : "mb-3 "} ${question.answer === key + "" ? "border-info" : "border-success"}  w-100`}>
                {
                    showEdit ?
                        updateButtons(key, ques) : null
                }
                {
                    ques.type === "TEXT" ? selectText(ques) :
                        ques.type === "AUDIO" ? selectAudio(ques) :
                            ques.type === "VIDEO" ? selectVideo(ques) :
                                ques.type === "IMAGE" ? selectImage(ques) :
                                    null
                }
                {
                    ques.isMain ? <>
                            <SpeechText
                                getSpeechText={getSpeechText}
                                questionNumber={1}
                            />
                            {showAnswers ?
                                <div><b>{ques.answer}</b></div> : null
                            }
                        </> :
                        null
                }
                {
                    showGains && ques.gains && Array.isArray(ques.gains) && ques.gains.length > 0 ?
                        <>
                            <hr/>
                            {
                                ques.gains.map((gain, key) =>
                                    <div key={key} className="mt-2">
                                        <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                            <fieldset className="w-100">
                                                <div> - {gain.gain}</div>

                                            </fieldset>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                        : null
                }
            </div>
        )
    }

    return (
        <div>
            {
                question ?
                    <React.Fragment>
                        <PageNavigation main="Componen List" sub="Home" header="Exam Activities"
                                        description="List of exams"/>
                        <div className="row justify-content-center mt-3">
                            <div className="col-12">
                                <div className="card border-0 shadow p-4 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <h3 className="fw-bold">{exam.name}</h3>
                                    </div>
                                    <p className="m-0">
                                        {exam.explain}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : null}
            {
                updateActivityButtons()
            }
            <div className="d-flex justify-content-end align-items-center mb-2">
                <InputLink to={`/exam/${id}/${question?.id}/new`} name="Question Add" type="add"/>
            </div>
            {
                question ?
                    <React.Fragment>
                        <div className="row justify-content-center mt-3">
                            <div className="col-12">
                                <div className="card border-0 shadow p-4 mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                  <span className="font-small">
                                          <h3 className="fw-bold">{question.orderNumber + " - "}{question.name} / {question.activityGroup}  </h3>
                                  </span>
                                    </div>
                                    {
                                        question && question.parts && Array.isArray(question.parts) ?
                                            question.parts.sort(compare).map((ques, key) =>
                                                <div key={key} className="mt-2">
                                                    <div className="col-lg-3 col-sm-6 mt-4 mt-md-0 w-100">
                                                        <fieldset className="w-100">
                                                            {
                                                                makeChoice(key, ques)
                                                            }
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            )

                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div
                            className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination mb-0">
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handleQuestionSelect(-1)}>Previous
                                        </button>
                                    </li>

                                    {
                                        exam && exam.activities && Array.isArray(exam.activities) ?
                                            exam.activities.sort(compare).map((exam, key) =>
                                                <li key={key} className={`page-item`}>
                                                    <button
                                                        className={`page-link ${qNumber === key ? "bg-warning" : !exam.answer && exam.answer === "" ? "" : "bg-success"}`}
                                                        onClick={() => {
                                                            setQNumber(key)
                                                            localStorage.setItem("qNumber", key)
                                                        }}>{key + 1}</button>
                                                </li>
                                            ) : null
                                    }
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handleQuestionSelect(1)}>Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </React.Fragment> : null}
        </div>
    )
}