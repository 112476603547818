import Request from "./Request";
import axios from "axios";
const config = require("../../configs/config.json");
const gain = require("../../configs/gain.json");

const createActivityPart = (activityId, activityPart) => {
    return Request("post", `/exam/activity/part/${activityId}`, activityPart);
};

const getAllActivityPart = () => {
    return Request("get", "/exam/activity/part/");
};

const updateActivityPart = (activityPartId, activityPart) => {

    return Request("put", `/exam/activity/part/${activityPartId}`, activityPart);
};

const getAllActiveActivityPart = () => {
    return Request("get", "/exam/activity/part/active/");
};

const getActivityPartById = (activityPartId) => {
    return Request("get", `/exam/activity/part/${activityPartId}`);
};

const getActivityPartByActivity = (activityPartId) => {
    return Request("get", `/activity/part/by-activity/${activityPartId}`);
};

const createActivity = (examId, activity) => {
    return Request("post", `/exam/activity/${examId}`, activity);
};

const getAllActivity = () => {
    return Request("get", `/exam/activity/`);
};

const updateActivity = (activityId, activity) => {
    return Request("put", `/exam/activity/${activityId}`, activity);
};

const deleteActivity = (activityId, examId) => {
    return Request("delete", `/exam/activity/${activityId}/${examId}`);
};

const getAllActiveActivity = (userId) => {
    return Request("get", `/activity/active/`);
};

const getActivityById = (activityId) => {
    return Request("get", `/exam/activity/${activityId}`);
};

const getActivityByExam = (examId) => {
    return Request("get", `/exam/activity/part/by-exam/${examId}`);
};

const addActivityPartToActivity = (activityId, activityPartId) => {
    return Request("get", `/exam/activity/add-part/${activityId}/${activityPartId}`);
};

const removeActivityPartToActivity = (activityId, activityPartId) => {
    return Request("get", `/exam/activity/remove-part/${activityId}/${activityPartId}`);
};

const createExam = (exam) => {
    return Request("post", `/exam/`, exam);
};

const deleteExam = (examId) => {
    return Request("delete", `/exam/${examId}`);
};

const getAllExam = async () => {
    return Request("get", `/exam/`);
};

const updateExam = (examId, exam) => {
    return Request("put", `/exam/${examId}`, exam);
};

const getAllActiveExam = () => {
    return Request("get", `/exam/active/`);
};

const getExamById = (examId) => {
    return  Request("get", `/exam/${examId}`);
};

const addActivityToExam = (examId, activityId) => {
    return Request("get", `/exam/activity/add-activity/${examId}/${activityId}`);
};

const removeActivityToExam = (examId, activityId) => {
    return Request("get", `/exam/activity/remove-activity/${examId}/${activityId}`);
};

const startExam = (studentId, name, lastname, email, mobilePhone, school, grade, branch, examId) => {
    return Request("get", `/exam/start/student/${studentId}/name/${name}/lastname/${lastname}/email/${email}/mobilePhone/${mobilePhone}/school/${school}/grade/${grade}/branch/${branch}/exam/${examId}`);
};


const getGains = async () => {
    return gain;

    try {
        const res = await axios.get("https://app.eltaexams.com/api/speech/kazanimlar", {
            params: { sinif: 0 },
            headers: {
                'Token': 'Bearer ad070e63-9299-4df7-9476-d8358d33bca9',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',  // CORS header
            },
            withCredentials: false
        });
        console.log(res);
        return res;
    } catch (error) {
        console.error('Hata:', error.response?.data || error.message);
    }

};



const addGain = (params) => {
    return Request("post", `/exam/gain/add/`, params);
};
const removeGain = (params) => {
    return Request("post", `/exam/gain/remove/`, params);
};





const getExamResults = (examId) => {
    return Request("get", `/result/${examId}`);
};

const uploadFileToPath = async (formData) => {
    try {
        const response = await axios.post(
            `${config.api.invokeUrl}/storage/upload/file`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return {data: response.data, status: response.status};
    } catch (err) {
        return {err};
    }
};



const deleteActivityPart = (activityId, activityPartId) => {
    return Request("delete", `/exam/activity/part/${activityId}/${activityPartId}`);
};

const ExamService = {
    deleteActivityPart,
    getExamResults,
    removeGain,
    addGain,
    createActivityPart,
    getGains,
    getAllActivityPart,
    updateActivityPart,
    getAllActiveActivityPart,
    getActivityPartById,
    getActivityPartByActivity,
    createActivity,
    getAllActivity,
    updateActivity,
    deleteActivity,
    getAllActiveActivity,
    getActivityById,
    getActivityByExam,
    addActivityPartToActivity,
    removeActivityPartToActivity,
    createExam,
    getAllExam,
    updateExam,
    getAllActiveExam,
    getExamById,
    addActivityToExam,
    removeActivityToExam,
    startExam,
    deleteExam,
    uploadFileToPath
};
export default ExamService;
