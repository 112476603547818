import axios from "axios";
import TokenService from "./token.service";
const siteConfig = require("../../configs/config.json");

const instance = axios.create({
  baseURL: siteConfig.api.invokeUrl,
  headers: {
    "Content-Type": "application/json",
  },
});


console.log(siteConfig.api.invokeUrl)

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {


    if (err.response) {

      let errorMessage = 'Bir hata oluştu';
      // Server tarafından dönen hata
      switch (err.response.status) {
        case 400:
          errorMessage = 'Geçersiz istek';
          break;
        case 401:
          errorMessage = 'Yetkisiz erişim';
          // Kullanıcıyı login sayfasına yönlendirebilirsiniz
          break;
        case 403:
          errorMessage = 'Erişim reddedildi';
          break;
        case 404:
          errorMessage = 'Kaynak bulunamadı';
          break;
        case 500:
          errorMessage = 'Sunucu hatası';
          break;
        default:
          errorMessage = `Bir hata oluştu (${err.response.status})`;


      }
      alert(errorMessage);
    } else if (err.request) {
      err = 'Sunucuya ulaşılamıyor';
    }




/*

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

 */

    return Promise.reject(err);
  }
);

export default instance;
