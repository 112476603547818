import React, {useContext, useEffect, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import FormInput from "../components/FormInput";
import { useInput } from "../../hooks/useInput";
import { AppContext } from "../../../configs/AppContextProvider";
import AuthService from "../../service/auth.service";

const model = require("../../domain/models.json");

export default function SingIn(props) {
  const [inputs, setInputs] = useInput({ ...model.login });
  const { user, setUser } = useContext(AppContext);

  const [examList, setExamList] = useState([]);

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    AuthService.login(inputs.email, inputs.password).then((response) => {
      setExamList(response);
    });
    //setAuth("login", {email: inputs.email, password: inputs.password})
   };


  return (
    user ? <Navigate to='/' /> :
      <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
          <div className="container">
            <p className="text-center">
              <Link
                to="/"
                className="d-flex align-items-center justify-content-center"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Back to homepage
              </Link>
            </p>
            <div
              className="row justify-content-center form-bg-image"
              data-background-lg="../../assets/img/illustrations/signin.svg"
            >
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h1 className="mb-0 h3">
                      Sign in Elta Management Application
                    </h1>
                  </div>
                  <form action="#" className="mt-4">
                    <div className="form-group mb-4">
                      <FormInput
                        name="email"
                        text="Your Email"
                        placeholder="example@company.com"
                        type="text"
                        col="12"
                        value={inputs.email}
                        onChange={setInputs}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-group mb-4">
                        <div className="input-group">
                          <FormInput
                            name="password"
                            text="Password Check"
                            placeholder="Password"
                            type="password"
                            col="12"
                            value={inputs.password}
                            onChange={setInputs}
                            required
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-top mb-4">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="remember"
                          />
                          <label className="form-check-label mb-0" htmlFor="remember">
                            Remember me
                          </label>
                        </div>
                        <div>
                          <Link to="/forgot" className="small text-right">
                            Lost password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-gray-800" onClick={handleLogin}>
                        Sign in
                      </button>
                    </div>
                  </form>
                  <div className="d-flex justify-content-center align-items-center mt-4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  );
}
