import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from "react";
import {Link} from "react-router-dom";


export default function Plan() {


    const WorkIcon = () => {
        return (
            <span className="vertical-timeline-element-icon bounce-in"
                  style={{background: "rgb(16, 204, 82);", color: "rgb(255, 255, 255);"}}>
                <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true">
                <path
                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                </path>
                </svg>
            </span>
        )
    }
    const SchoolIcon = () => {
        return (
            <span className="vertical-timeline-element-icon bounce-in"
                  style={{background: "rgb(33, 150, 243);", color: "rgb(255, 255, 255);"}}>
                <svg className="MuiSvgIcon-root"
                     focusable="false"
                     viewBox="0 0 24 24"
                     aria-hidden="true">
                    <path
                        d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z">
                    </path>
                </svg>
            </span>
        )
    }
    const StarIcon = () => {
        return (
            <span className="vertical-timeline-element-icon bounce-in"
                  style={{background: "rgb(16, 204, 82);", color: "rgb(255, 255, 255);"}}>
                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z">
                    </path>
                </svg>
            </span>
        )
    }


    const planData = [
        {
            "id": 0,
            "name": "Are you a Risk Taker",
            "explain": "Grammar explanation",
            "description": "Do you know how to use comparative adjectives like older, better and more interesting? Test what you know with interactive exercises and read the explanation to help you.",
            "date": "1 Jan 2024 18.00",
            "background": true,
            "icon": <WorkIcon/>,
            "iconColor": "",
            "absent": true
        },
        {
            "id": 1,
            "name": "Are we killing or promoting creativity?",
            "explain": "Adjectives with one syllable",
            "description": "Comparative adjectives are used to compare differences between the two objects they modify (larger, smaller, faster, higher). They are used in sentences where two nouns are compared, in this pattern:",
            "date": "5 Jan 2024 18.00",
            "background": true,
            "icon": <WorkIcon/>,
            "iconColor": "",
            "absent": false
        },
        {
            "id": 2,
            "name": "Cities",
            "explain": "Modals, the imperative, etc .",
            "description": "Superlative adjectives are used to describe an object which is at the upper or lower limit of a quality (the tallest, the smallest, the fastest, the highest). They are used in sentences where a subject is compared to a group of objects.",
            "date": "22 Jan 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 3,
            "name": "Crime and Punishment",
            "explain": "Conditionals, if, wish, etc.",
            "description": "Forming comparatives and superlatives is easy. The form depends on the number of syllables in the original adjective.",
            "date": "20 Jan 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 4,
            "name": "Customs and Traditions in Spain",
            "explain": "-ing and the infinitive",
            "description": "Adjectives with two syllables can form the comparative either by adding -er or by preceeding the adjective with more. These adjectives form the superlative either by adding -est or by preceeding the adjective with most.",
            "date": "5 Feb 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 5,
            "name": "Education is the most powerful weapon you can use to change the world",
            "explain": "Passive",
            "description": "Add -er for the comparative and -est for the superlative. If the adjective has a consonant + single vowel + consonant spelling, the final consonant must be doubled before adding the ending.",
            "date": "12 Feb 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 6,
            "name": "Food: Food for Thought Issues",
            "explain": "Irregular comparatives and superlatives",
            "description": "Adjectives with three or more syllables form the comparative by putting more in front of the adjective, and the superlative by putting most in front.",
            "date": "19 Feb 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 7,
            "name": "-ed –ing Adjectives: The Supporting Act",
            "explain": "Articles, nouns, pronouns, and determiners.",
            "description": "These very common adjectives have completely irregular comparative and superlative forms.",
            "date": "26 Feb 2024 18.00",
            "background": false,
            "icon": <SchoolIcon/>,
            "iconColor": ""
        },
        {
            "id": 8,
            "name": "The Environment: Save Mother Earth",
            "explain": "Relative clauses, relative pronouns and adverbs",
            "description": "If you are not sure whether a two-syllable adjective can take a comparative or superlative ending, play it safe and use more and most instead. For adjectives ending in y, change the y to an i before adding the ending.",
            "date": "4 March 2024 18.00",
            "background": false,
            "icon": <StarIcon/>,
            "iconColor": ""
        },

    ]
    return (
        <React.Fragment>

            <VerticalTimeline>

                {
                    planData.map((plan, key) =>

                        <VerticalTimelineElement
                            className="vertical-timeline-element--work" date={plan.date}
                            contentStyle={plan.background === true ? {
                                background: 'rgb(33, 150, 243)',
                                color: '#aaa'
                            } : {}}
                            contentArrowStyle={plan.background === true ? {borderRight: '7px solid  rgb(33, 150, 243)'} : {}}
                            iconStyle={plan.background === true ? {
                                background: 'rgb(33, 150, 243)',
                                color: '#fff'
                            } : {background: 'rgb(233, 30, 99)', color: '#fff'}}
                            icon={<WorkIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">{plan.name}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{plan.explain}</h4>
                            <div className="mt-4">
                                {
                                    plan.background === true ?
                                        <Link to="/lesson" className="vertical-timeline-element btn btn-success">Watch
                                            Lesson
                                            Record</Link> :
                                        <Link
                                            to="https://www.youtube.com/results?search_query=learn+english+with+friends"
                                            target="_blank" className="vertical-timeline-element btn btn-success">Join
                                            Lesson</Link>
                                }
                            </div>
                            {
                                plan.absent ?
                                    <div className="vertical-timeline-element btn btn-danger">! You Didn't Go to
                                        Class.</div> :
                                    null
                            }

                            <p>
                                {plan.description}
                            </p>
                        </VerticalTimelineElement>
                    )
                }

                <VerticalTimelineElement
                    iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
                    icon={<StarIcon/>}
                />
            </VerticalTimeline>

        </React.Fragment>
    )
}