import Exam from "../app/pages/exam/Exam";

import ActivityEdit from "../app/pages/exam/ActivityEdit";
import ActivityPartEdit from "../app/pages/exam/ActivityPartEdit";
import DoExam from "../app/pages/exam/DoExam";
import ExamEdit from "../app/pages/exam/ExamEdit";
import SingIn from "../app/pages/single/SingIn";
import {MdOutlineContactPage} from "react-icons/md";
import ExamResults from "../app/pages/exam/ExamResults";
import ApplicationResult from "../app/pages/exam/ApplicationResult";


const dashboardRoutes = [

  {
    path: "/",
    name: "Exam List",
    icon: MdOutlineContactPage,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 0,
    sidePanelVisible: true,
    fullScreen: false
  },


  {
    path: "/sing-in",
    name: "Exam List",
    icon: MdOutlineContactPage,
    component: SingIn,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 0,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/applications/:examId",
    name: "Applications",
    icon: MdOutlineContactPage,
    component: ExamResults,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },

  {
    path: "/applications/",
    name: "Applications",
    icon: MdOutlineContactPage,
    component: ApplicationResult,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: true,
    fullScreen: false
  },
  {
    path: "/report",
    name: "Report",
    icon: MdOutlineContactPage,
    component: Exam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },





  {
    path: "/exam/:examId/:activityId",
    name: "ActivityEdit",
    icon: MdOutlineContactPage,
    component: ActivityEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/:examId/:activityId/:activityPartId",
    name: "ActivityPartEdit",
    icon: MdOutlineContactPage,
    component: ActivityPartEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/:id",
    name: "Report",
    icon: MdOutlineContactPage,
    component: DoExam,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  },
  {
    path: "/exam/create/:examId",
    name: "ExamEdit",
    icon: MdOutlineContactPage,
    component: ExamEdit,
    menu: true,
    location: "",
    overPath: "EXAMS",
    auth: ["SUPER_ADMIN", "ADMIN"],
    sort: 1,
    sidePanelVisible: false,
    fullScreen: false
  }

];

export default dashboardRoutes;
