import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";


const newActivity = {
    id: -1,
    deletedAt: null,
    status: "ACTIVE",
    type: "TEXT",
    name: "",
    directive: "",
    activityGroup: null,
    orderNumber: 1,
    parts: []
}

export default function ActivityEdit() {

    let {examId, activityId} = useParams();
    const [selectedActivity, setSelectedActivity] = useState(null);

    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        if (activityId === "new") {
            setIsNew(true);
            setSelectedActivity(newActivity);
            return;
        }
        ExamService.getActivityById(activityId).then((response) => {
            setSelectedActivity(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const saveActivityPartEvent = () => {

        if (activityId === "new") {
            ExamService.createActivity(examId, selectedActivity).then((response) => {
                setSelectedActivity(response.data);
                alert("Created");
            });
        } else {
            ExamService.updateActivity(activityId, selectedActivity).then((response) => {
                setSelectedActivity(response.data);
                alert("Updated");
            });
        }
    }


    return (


        selectedActivity ?
            <div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Activity</h2>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Activity Type</label>
                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                activityGroup: e.target.value
                                            })}
                                            value={selectedActivity.activityGroup}
                                        >
                                            <option value="WARM_UP">WARM_UP</option>
                                            <option value="LISTENING_AND_PRONUNCIATION">LISTENING_AND_PRONUNCIATION</option>
                                            <option value="TEXT_READING_AND_SPEAKING">TEXT_READING_AND_SPEAKING</option>
                                            <option value="IMAGE_ANALYSIS">IMAGE_ANALYSIS</option>
                                            <option value="VIDEO_ANALYSIS">VIDEO_ANALYSIS</option>


                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="zip">Order Number</label>
                                        <input
                                            className="form-control"
                                            id="zip"
                                            type="tel"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                orderNumber: e.target.value
                                            })}
                                            value={selectedActivity.orderNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Status</label>

                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                status: e.target.value
                                            })}
                                            value={selectedActivity.status}
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="PASSIVE">PASSIVE</option>
                                        </select>

                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                name: e.target.value
                                            })}
                                            value={selectedActivity.name}
                                        />
                                    </div>
                                </div>
                               <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Directive</label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            type="email"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                directive: e.target.value
                                            })}
                                            value={selectedActivity.directive}
                                        />
                                    </div>
                                </div>






                                <div className="mt-3">
                                    <button onClick={saveActivityPartEvent}
                                            className="btn btn-gray-800 mt-2 animate-up-2">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="card shadow border-0 text-center p-0">
                                    <div
                                        className="profile-cover rounded-top"
                                        data-background="../assets/img/profile-cover.jpg"
                                    ></div>
                                    <div className="card-body pb-5">

                                        <h4 className="h3">Neil Sims</h4>
                                        <h5 className="fw-normal">Senior Software Engineer</h5>
                                        <p className="text-gray mb-4">New York, USA</p>
                                        <Link
                                            className="btn btn-sm btn-gray-800 d-inline-flex align-items-center me-2"
                                            to="#"
                                        >
                                            <svg
                                                className="icon icon-xs me-1"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                                                ></path>
                                            </svg>
                                            Connect </Link
                                        ><Link className="btn btn-sm btn-secondary" to="#">Send Message</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            null


    )
}