import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";


const newActivityPart = {

    id: -1,
    deletedAt: null,
    status: "ACTIVE",
    type: "TEXT",
    content: "",
    url: "",
    file: null,
    answer: "",
    orderNumber: 1,
    rubric: "",
    isMain: false,
    duration: 0,
    repeatCount: 1,
    score: 1.0,
    gains: []

}

export default function ActivityPartEdit() {

    let {examId, activityId, activityPartId} = useParams();
    const [selectedActivityPart, setSelectedActivityPart] = useState(null);

    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        if (activityPartId === "new") {
            setIsNew(true);
            setSelectedActivityPart(newActivityPart);
            return;
        }
        ExamService.getActivityPartById(activityPartId).then((response) => {
            setSelectedActivityPart(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const saveActivityPartEvent = () => {

        if (activityPartId === "new") {
            ExamService.createActivityPart(activityId, selectedActivityPart).then((response) => {
                setSelectedActivityPart(response.data);
                alert("Created");
            });
        } else {
            ExamService.updateActivityPart(activityPartId, selectedActivityPart).then((response) => {
                setSelectedActivityPart(response.data);
                alert("Updated");
            });
        }
    }


    const deleteGainEvent = (deletedGain) => {
        selectedActivityPart.gains = selectedActivityPart.gains.filter(gain => gain.id !== deletedGain.id);
        setSelectedActivityPart({...selectedActivityPart});
    }


    return (


        selectedActivityPart && selectedActivityPart.id ?
            <div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Question Part</h2>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Question Digital Type</label>
                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                type: e.target.value
                                            })}
                                            value={selectedActivityPart.type}
                                        >
                                            <option value="TEXT">TEXT</option>
                                            <option value="IMAGE">IMAGE</option>
                                            <option value="AUDIO">AUDIO</option>
                                            <option value="VIDEO">VIDEO</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 form-check form-switch">
                                    <div>

                                        <label htmlFor="first_name">Is Main?</label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) =>
                                                setSelectedActivityPart({
                                                    ...selectedActivityPart,
                                                    isMain: !selectedActivityPart.isMain
                                                })
                                            }
                                            checked={selectedActivityPart.isMain}
                                        />

                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="zip">Order Number</label>
                                        <input
                                            className="form-control"
                                            id="zip"
                                            type="tel"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                orderNumber: e.target.value
                                            })}
                                            value={selectedActivityPart.orderNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Status</label>

                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                status: e.target.value
                                            })}
                                            value={selectedActivityPart.status}
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="PASSIVE">PASSIVE</option>
                                        </select>

                                    </div>
                                </div>
                                {selectedActivityPart.type === "TEXT" ? <div className="col-md-12 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Content</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                content: e.target.value
                                            })}
                                            value={selectedActivityPart.content}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Answer</label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            type="email"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                answer: e.target.value
                                            })}
                                            value={selectedActivityPart.answer}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.type !== "TEXT" ? <div className="col-sm-8 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="city">Document Url</label>
                                        <input
                                            className="form-control"
                                            id="city"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                url: e.target.value
                                            })}
                                            value={selectedActivityPart.url}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.type !== "TEXT" ? <div className="col-sm-4 mb-3">
                                    <label htmlFor="state">File</label>
                                    <input
                                        className="form-control"
                                        id="zip"
                                        type="tel"
                                        onChange={(e) => setSelectedActivityPart({
                                            ...selectedActivityPart,
                                            file: e.target.value
                                        })}
                                        value={selectedActivityPart.file}
                                    />
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="address">Rubric</label>
                                        <input
                                            className="form-control"
                                            id="address"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                rubric: e.target.value
                                            })}
                                            value={selectedActivityPart.rubric}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-4 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="city">Duration</label>
                                        <input
                                            className="form-control"
                                            id="city"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                duration: e.target.value
                                            })}
                                            value={selectedActivityPart.duration}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-4 mb-3">
                                    <label htmlFor="state">Repeat Count</label>
                                    <select
                                        className="form-select w-100 mb-0"
                                        onChange={(e) => setSelectedActivityPart({
                                            ...selectedActivityPart,
                                            repeatCount: e.target.value
                                        })}
                                        value={selectedActivityPart.repeatCount}
                                        aria-label="State select example"
                                    >
                                        <option selected="selected">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="1000">-</option>
                                    </select>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="zip">Max. Score</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                score: e.target.value
                                            })}
                                            value={selectedActivityPart.score}
                                        />
                                    </div>
                                </div> : null}
                                <div className="mt-3">
                                    <button onClick={saveActivityPartEvent}
                                            className="btn btn-gray-800 mt-2 animate-up-2">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>

                        {
                            selectedActivityPart.isMain ?
                                <div className="card card-body border-0 shadow mb-4 mb-xl-0">
                                    <h2 className="h5 mb-4">Gains</h2>
                                    <ul className="list-group list-group-flush">

                                        {
                                            selectedActivityPart.gains.map((gain, key) => (
                                                <li
                                                    className="d-flex align-items-center justify-content-between py-2 border-bottom"
                                                    key={key}
                                                >
                                                    <div className="small pe-4">
                                                        {gain.name}
                                                    </div>
                                                    <div className="form-group">
                                                        <button className="btn btn-danger"
                                                                onClick={e => deleteGainEvent(gain)}>Delete
                                                        </button>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                : null
                        }
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="card shadow border-0 text-center p-0">
                                    <div
                                        className="profile-cover rounded-top"
                                        data-background="../assets/img/profile-cover.jpg"
                                    ></div>
                                    <div className="card-body pb-5">

                                        <h4 className="h3">Neil Sims</h4>
                                        <h5 className="fw-normal">Senior Software Engineer</h5>
                                        <p className="text-gray mb-4">New York, USA</p>
                                        <Link
                                            className="btn btn-sm btn-gray-800 d-inline-flex align-items-center me-2"
                                            to="#"
                                        >
                                            <svg
                                                className="icon icon-xs me-1"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                                                ></path>
                                            </svg>
                                            Connect </Link
                                        ><Link className="btn btn-sm btn-secondary" to="#">Send Message</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            null


    )
}