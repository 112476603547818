import React from "react";
import ReactPlayer from 'react-player'

export default function Lesson (){

    return(
        <React.Fragment>
            <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                         controls={true}
                         width="100%"
            />
        </React.Fragment>
    )
}