import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import { useRole } from "../../hooks/useRole";

export default function ShortMenu() {
  let location = useLocation();
  const [role, checkRole] = useRole(true);
  const [visibility, setVisibility] = useState(true);


  useEffect(() => {
     checkRole("menu",location.pathname, "Admin")
  }, [checkRole, location.pathname]);


  useEffect(() => {
    setVisibility(role)
 }, [role]);
  
  return (
    visibility === true ?
    <nav className="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
      <Link className="navbar-brand me-lg-5" to="/">
        <img
          className="navbar-brand-dark"
          src="./assets/img/brand/light.svg"
          alt="Elta logo"
        />
        <img
          className="navbar-brand-light"
          src="./assets/img/brand/dark.svg"
          alt="Elta logo"
        />
      </Link>
      <div className="d-flex align-items-center">
        <button
          className="navbar-toggler d-lg-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
    : null
  );
}
