import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) => {
    return api.post("/auth/register", {
        username,
        email,
        password
    });
};

const login = (username, password) => {
console.log(username, password);
    return api
        .post("/auth/authenticate", {
            username,
            password
        })
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const check = () => {

    try {
        const response = api.get("/health/");
        ;
        return response.data;
    } catch (error) {
        const originalConfig = error.config;

        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                TokenService.removeUser();
                window.location.href = '/sing-in';
                return Promise.reject(error);
            }
        }
    }

};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    check
};

export default AuthService;
