import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  return props.buttons ? (
    <div className="btn-group mb-3 me-3 mb-md-0 d-none d-md-inline-flex">
      {props.buttons
        ? props.buttons.map((button, key) => (
            <button key={key} className="btn btn-gray-800" onClick={()=>button.event}>
              {button.icon}
            </button>
          ))
        : null}
    </div>
  ) : (
    <div>
      {props.event ? (
        <button
          onClick={()=>props.event}
          className="btn btn-secondary d-inline-flex align-items-center mb-3 mb-md-0"
        >
          {props.icon}
          {props.text}
        </button>
      ) : (
        <Link
          to={props.link}
          className="btn btn-secondary d-inline-flex align-items-center mb-3 mb-md-0"
        >
          {props.icon}
          {props.text}
        </Link>
      )}
    </div>
  );
}
