import React from "react";
import {isArray} from "chart.js/helpers";

export default function FormSelect(props) {

    const controlSelected = () => {
        let result = false;
        if (props.data && isArray((props.data))) {
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i][props.valuekey] === props.value) {
                    result = true;
                }
            }
        }
        return result
    }


    const isControlSelected = (key) => {
        let result = false;


        if (props.data && isArray((props.data))) {
            for (let i = 0; i < props.data.length; i++) {
                if (props.data[i][props.valuekey] === props.value) {
                    result = true;
                }

            }

            if(result === false && key===0) return true
        }
        return result
    }
    return (
        <div className={`col-md-${props.col} mb-3`}>
            {
                props.text ?
                    <label htmlFor={`id_${props.id}`}>{props.text}</label>
                    : null
            }
            <select
                className="form-select w-100 mb-0"
                name={`${props.name}`}
                aria-label="Select one"
                onChange={props.onChange}
                required
            >

                {
                    controlSelected ?
                        <option selected
                                value=""> </option>
                        : null
                }

                {props.data ? props.data.map((v, key) => (



                    props.value && v[props.valuekey] ===  props.value?
                        <option selected key={key}
                                value={v[props.valuekey]}>{v[props.textkey]}</option>
                        :


                        <option key={key}
                                value={v[props.valuekey]}>{v[props.textkey]}</option>



                )) : null}
            </select>
        </div>
    );
}
