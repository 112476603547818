import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {FaChartBar} from "react-icons/fa";
import {FaCirclePlus, FaCircleQuestion, FaCircleXmark} from "react-icons/fa6";

export default function InputLink(props) {
    const {to, name, type, clickEvent} = props;
    const [color, setColor] = React.useState("btn-gray-800");

    useEffect(() => {
        if (type === "add") {
            setColor("btn-primary");
        }
        if (type === "edit") {
            setColor("btn-warning");
        }
        if (type === "delete") {
            setColor("btn-danger");
        }
        if (type === "list") {
            setColor("btn-info");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        !clickEvent ?
            <Link className={`btn btn-sm ${color} d-inline-flex align-items-center mx-1`} to={to}>
                {
                    type === "add" ? <FaCirclePlus size={22} className="mx-2"/> :
                        type === "edit" ? <FaCircleQuestion size={22} className="mx-2"/> :
                            type === "delete" ? <FaCircleXmark size={22} className="mx-2"/> :
                                type === "list" ? <FaChartBar size={22} className="mx-2"/> : null

                }
                {name}</Link>
            :
            <button className={`btn btn-sm ${color} d-inline-flex align-items-center mx-1`} onClick={clickEvent}>
                {
                    type === "add" ? <FaCirclePlus size={22} className="mx-2"/> :
                        type === "edit" ? <FaCircleQuestion size={22} className="mx-2"/> :
                            type === "delete" ? <FaCircleXmark size={22} className="mx-2"/> :
                                type === "list" ? <FaChartBar size={22} className="mx-2"/> : null

                }
                {name}</button>

    );
}