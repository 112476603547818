import React, {useContext, useEffect} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";
import { useInput } from "../../hooks/useInput";
import { AppContext } from "../../../configs/AppContextProvider";
import AuthService from "../../service/auth.service";


const model = require("../../domain/models.json");

export default function SingIn(props) {
  const navigate = useNavigate();
  const [inputs, setInputs] = useInput({ ...model.login });
  const { user } = useContext(AppContext);


  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    AuthService.login(inputs.email, inputs.password).then((response) => {
      navigate('/');
    });

   };


  return (
    user ? <Navigate to='/' /> :
      <main>
        <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
          <div className="container">

            <div
              className="row justify-content-center form-bg-image"
              data-background-lg="../../assets/img/illustrations/signin.svg"
            >
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h1 className="mb-0 h3">
                      Sign in Elta Management Application
                    </h1>
                  </div>
                  <form action="#" className="mt-4">
                    <div className="form-group mb-4">
                      <FormInput
                        name="email"
                        text="Username"
                        type="text"
                        col="12"
                        value={inputs.email}
                        onChange={setInputs}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-group mb-4">
                        <div className="input-group">
                          <FormInput
                            name="password"
                            text="Password"
                            type="password"
                            col="12"
                            value={inputs.password}
                            onChange={setInputs}
                            required
                          />
                        </div>
                      </div>

                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-gray-800" onClick={handleLogin}>
                        Sign in
                      </button>
                    </div>
                  </form>
                  <div className="d-flex justify-content-center align-items-center mt-4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  );
}
