import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamService from "../../service/exam.service";
import PageNavigation from "../components/PageNavigation";
import {useNavigate } from "react-router-dom";
import InputLink from "../components/InputLink";


const newActivity = {
    id: -1,
    deletedAt: null,
    status: "ACTIVE",
    type: "TEXT",
    name: "",
    directive: "",
    activityGroup: "WARM_UP",
    orderNumber: 1,
    parts: []
}

export default function ActivityEdit() {

    let {examId, activityId} = useParams();
    const navigate = useNavigate();
    const [selectedActivity, setSelectedActivity] = useState(newActivity);

    const [, setIsNew] = useState(false);

    useEffect(() => {
        if (!activityId || activityId === "new") {
            setIsNew(true);
            setSelectedActivity(newActivity);
            return;
        }
        ExamService.getActivityById(activityId).then((response) => {
            setSelectedActivity(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const saveActivityPartEvent = () => {

        if (activityId === "new") {
            ExamService.createActivity(examId, selectedActivity).then((response) => {
                setSelectedActivity(response);
                navigate('/exam/' + examId);
            });
        } else {
            ExamService.updateActivity(activityId, selectedActivity).then((response) => {
                setSelectedActivity(response);
                navigate('/exam/' + examId);
            });
        }
    }

    const deleteActivityPartEvent = () => {

        if (activityId === "new") {

        } else {
            ExamService.deleteActivity(activityId, examId).then((response) => {
                setSelectedActivity(response);
                navigate('/exam/' + examId);
            });
        }
    }


    return (


        selectedActivity ?
            <div>
                <PageNavigation main="Componen List" sub="Home" header="Activity Operations" description="Activity Operations"/>
                <div className="row my-3">
                    <div className="col-12 col-xl-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Activity</h2>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Activity Type</label>
                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                activityGroup: e.target.value
                                            })}
                                            value={selectedActivity.activityGroup}
                                        >
                                            <option value="WARM_UP">WARM_UP</option>
                                            <option value="LISTENING_AND_PRONUNCIATION">LISTENING_AND_PRONUNCIATION
                                            </option>
                                            <option value="TEXT_READING_AND_SPEAKING">TEXT_READING_AND_SPEAKING</option>
                                            <option value="IMAGE_ANALYSIS">IMAGE_ANALYSIS</option>
                                            <option value="VIDEO_ANALYSIS">VIDEO_ANALYSIS</option>


                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="zip">Order Number</label>
                                        <input
                                            className="form-control"
                                            id="zip"
                                            type="tel"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                orderNumber: e.target.value
                                            })}
                                            value={selectedActivity.orderNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Status</label>

                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                status: e.target.value
                                            })}
                                            value={selectedActivity.status}
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="PASSIVE">PASSIVE</option>
                                        </select>

                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                name: e.target.value
                                            })}
                                            value={selectedActivity.name}
                                        />
                                    </div>
                                </div>
                               <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Directive</label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            type="email"
                                            onChange={(e) => setSelectedActivity({
                                                ...selectedActivity,
                                                directive: e.target.value
                                            })}
                                            value={selectedActivity.directive}
                                        />
                                    </div>
                                </div>


                                <div className="mt-3">
                                    <button onClick={saveActivityPartEvent}
                                            className="btn btn-gray-800 mt-2 animate-up-2">
                                        Save
                                    </button>





                                </div>

                                {
                                    activityId !== "new" ?
                                        <div className="text-end">
                                            <InputLink clickEvent={deleteActivityPartEvent} name="Activity Delete"
                                                       type="delete"/></div>

                                        : null
                                }



                            </div>
                        </div>


                    </div>

                </div>
            </div>
            :
            null


    )
}