import React, {useEffect, useState} from "react";
import PageNavigation from "../components/PageNavigation";
import {Link} from "react-router-dom";

import ExamService   from "../../service/exam.service";

export default function Exam() {

    const [examList, setExamList] = useState([]);

    useEffect(() => {
        ExamService.getAllExam().then((response) => {
            setExamList(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const examRow = () => {
        return (
            examList && Array.isArray(examList) ?
                examList.map((exam, key) =>
                        <tr key={key}>
                            <td>
                                <Link to="#" className="d-flex align-items-center"
                                >
                                    <div
                                        className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3"
                                    >
                                        <span>{key+1}</span>
                                    </div>
                                    <div className="d-block">
                                        <span className="fw-bold">{exam.name}</span>
                                        <div className="small text-gray">
                                            <span className="__cf_email__">{exam.explain}</span>
                                        </div>
                                    </div>
                                </Link>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}><span className="fw-normal">{exam.id}</span></td>
                            <td>
                <span className="fw-normal d-flex align-items-center">
                    <svg
                        className="icon icon-xxs text-success me-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd">
                    </path>
                  </svg>
                  Email</span>
                            </td>
                            <td>
                                {
                                    exam.status ?
                                        <span className="fw-normal text-success">Active</span> :
                                        <span className="fw-normal text-danger">Suspended</span>
                                }
                            </td>
                            <td>
                                <Link
                                    to={`/exam/${exam.id}`}
                                    className="btn btn-icon-only btn-youtube d-inline-flex align-items-center"
                                    type="button">
                                    <svg
                                        className="icon icon-xxs"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="youtube"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 576 512">
                                        <path
                                            fill="currentColor"
                                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                        ></path>
                                    </svg>
                                </Link
                                >
                            </td>
                        </tr>
                )
                : null
        )
    }

    return (
        <React.Fragment>

            <PageNavigation main="Componen List" sub="Home" header="User List" description="List of components" btnLink="/exam/create/new"
                            linkName="Add Exam"/>

            <div className="card card-body shadow border-0 table-wrapper table-responsive mt-3">
                <div className="d-flex mb-3">
                    <select
                        className="form-select fmxw-200"
                        aria-label="Message select example">
                        <option selected="selected">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                        <option value="3">Pending</option>
                        <option value="3">Cancelled</option>
                    </select>
                    <button className="btn btn-sm px-3 btn-secondary ms-3">Apply</button>
                </div>
                <table className="table user-table table-hover align-items-center">
                    <thead>
                    <tr>
                        <th className="border-bottom">Name</th>
                        <th className="border-bottom">ID</th>
                        <th className="border-bottom">Verified</th>
                        <th className="border-bottom">Status</th>
                        <th className="border-bottom">Action</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        examRow()
                    }

                    </tbody>
                </table>
                <div
                    className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between"
                >
                    <nav aria-label="Page navigation example">
                        <ul className="pagination mb-0">
                            <li className="page-item">
                                <Link className="page-link" to="#">Previous</Link>
                            </li>
                            <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                            <li className="page-item active">
                                <Link className="page-link" to="#">2</Link>
                            </li>
                            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">4</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">5</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">Next</Link></li>
                        </ul>
                    </nav>
                    <div className="fw-normal small mt-4 mt-lg-0">
                        Showing <b>5</b> out of <b>25</b> entries
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}