import {
    useState
} from "react";
import Request from "./Request";

const config = require("../../configs/config.json");
const document = require("../../data/document.json");
const courses = require("../../data/courses.json");
const exams = require("../../data/exams.json");

export const useApi = () => {
    const [result, setResult] = useState(null);





    const application_all_get = async (limit, offset, search, tenantId) => {
        const response = await Request("get", `${config.api.invokeChirpStackUrl}/applications/?limit=${limit}&offset=${offset}&search=${search}&tenantId=${tenantId}`);
        setResult(response);
    };
    const application_post = async (body) => {
        const response = await Request("post", `${config.api.invokeChirpStackUrl}/applications/`, body);
        setResult(response);
    };
    const application_put = async (applicationId, body) => {
        const response = await Request("put", `${config.api.invokeChirpStackUrl}/applications/${applicationId}`, body);
        setResult(response);
    };
    const application_delete = async (applicationId) => {
        const response = await Request("delete", `${config.api.invokeChirpStackUrl}/applications/${applicationId}`);
        setResult(response);
    };



    const course_all_get = async () => {
        setResult(courses);
    };
    const document_all_get = async () => {
        setResult(document);
    };
    const document_by_id = async (documentId) => {
        setResult(document.find(d=> d.id === documentId));
    };


    const exam_all_get = async () => {
        setResult(exams);
    };
    const exam_by_id = async (examId) => {
        setResult(exams.find(d=> Number(d.id) === Number(examId)));
    };


    const handleChange = async (type, data) => {
        if (type === "application_all_get") {
            await application_all_get(data["limit"], data["offset"], data["search"], data["tenantId"]);
        } else if (type === "application_post") {
            await application_post(data["body"]);
        } else if (type === "application_put") {
            await application_put(data["applicationId"], data["body"]);
        } else if (type === "application_delete") {
            await application_delete(data["applicationId"]);




        } else if (type === "course_all_get") {
            await course_all_get();
        } else if (type === "document_all_get") {
            await document_all_get();
        } else if (type === "document_by_id") {
            await document_by_id(data["documentId"]);
        } else if (type === "exam_all_get") {
            await exam_all_get();
        } else if (type === "exam_by_id") {
            await exam_by_id(data["examId"]);
        }
    };


    return [result, handleChange];
};