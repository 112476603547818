import React, { useState, useEffect, useContext } from 'react'
import HideShowButton from "./header/HideShowButton";
import Search from "./header/Search";
import {useLocation, useNavigate} from "react-router-dom";
import { useRole } from "../../hooks/useRole";
import { AppContext } from "../../../configs/AppContextProvider";
import { NotificationContainer } from 'react-notifications';


export default function Header() {
  const { tenant } = useContext(AppContext);
  let location = useLocation();
  const [role, checkRole] = useRole(true);
  const [visibility, setVisibility] = useState(true);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    checkRole("menu", location.pathname, "Admin")
  }, [checkRole, location.pathname]);




  useEffect(() => {
    var user_data = localStorage.getItem("user_data")
    setUser(JSON.parse(user_data))
  }, []);


  useEffect(() => {
    setVisibility(role)
  }, [role]);

  const sing_out = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("user_data")
    window.location.reload(false);
    navigate("/sing-in");
  }

  return (<React.Fragment>
    {visibility === true ?
      <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0 mb-5">
        <div className="container-fluid px-0">
          <div
            className="d-flex justify-content-between w-100"
            id="navbarSupportedContent"
          >
            <div className="d-flex align-items-center">
              <HideShowButton />
              <Search />
            </div>
            <ul className="navbar-nav align-items-center">
              {tenant ? <div className='btn btn-secondary m-2'>{tenant.name}</div> : null}
              {
                //<Notification />
              }
              {
                <span className='m-2 btn btn-secondary'>Ceyhun Tekkaya</span>
              }
              <button className='m-2 btn btn-danger' onClick={() => sing_out()}>Çıkış</button>
            </ul>
          </div>
        </div>
      </nav>
      : null}
    <NotificationContainer />
  </React.Fragment>
  );
}
