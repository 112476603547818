import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {FaBackwardFast} from "react-icons/fa6";
import {FaPlus} from "react-icons/fa";

export default function PageNavigation(props) {
    const navigate = useNavigate();

    const handleGoBack = () => {
        try {
            navigate(-1);
        } catch {

        }
    };

    return (
        <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center bg-white p-3 rounded">
            <div className="d-flex mb-4 mb-md-0">
                <button className="btn btn-outline-light m-1" onClick={handleGoBack}><FaBackwardFast size={22}  color="gray"/></button>
                <h1 className="h4 pt-2"> {props.header}</h1>
            </div>
            <div className="btn-toolbar mb-2 mb-md-0">
                {
                    props.btnLink ?
                        <Link
                            to={props.btnLink}
                            className="btn btn-sm btn-gray-800 d-inline-flex align-items-center"
                        >
                            <FaPlus className={"mx-sm-2"}/>
                            {props.linkName}
                        </Link>
                        : null
                }

            </div>
        </div>
    );
}
